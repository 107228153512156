import React, { Component } from 'react';
import './Navigator.css';
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap"
import Alert from '../Alert/Alert'
import Plot from '../Backdrop/Plot'
import MultiPlot from './MultiPlot'
import DeviceDetails from '../DeviceDetails/DeviceDetails'
import Numeric from './Numeric/Numeric.js'
import RealTime from '../../components/RealTime/RealTime';
import Calibration from '../Calibration/Calibration';
import {MySideBarContext} from '../Sidebar/Sidebar'
import IssueModal from './IssueModal'
import { FaBullseye } from 'react-icons/fa';
import axios from 'axios';
// import View from '../View/View'


class Navigator extends Component {
    constructor(props) {
        super(props);
        this.state={
            timestamp_list:this.props.timestamp_list,
            mean:this.props.mean,
          plot_bool:false,
          multiPlot_bool:false,
          deviceDetails_bool:false,
          numeric_bool:true,
          realtime_bool:false,
          calibrate_bool:false,
          alert:false
        }
    }

    numeric=()=>{
        var i;
        for(i=0;i<document.getElementsByClassName("b").length;i++){
            document.getElementsByClassName("b")[i].style.backgroundColor="white"
            document.getElementsByClassName("b")[i].style.color="#333"
        }
        document.getElementById("numeric").style.backgroundColor="#191a1a"
        document.getElementById("numeric").style.color="#eee"
        this.setState({numeric_bool:true,plot_bool:false, multiPlot_bool:false,deviceDetails:false, log_bool:false, realtime_bool:false, calibrate_bool:false, alert:false})
    }
    plot=()=>{
        var i;
        for(i=0;i<document.getElementsByClassName("b").length;i++){
            document.getElementsByClassName("b")[i].style.backgroundColor="white"
            document.getElementsByClassName("b")[i].style.color="#333"
        }
        document.getElementById("plot").style.backgroundColor="#191a1a"
        document.getElementById("plot").style.color="#eee"
        this.setState({plot_bool:true,multiPlot_bool:false, numeric_bool:false,deviceDetails_bool:false, log_bool:false, realtime_bool:false, calibrate_bool:false, alert:false})
    }
    multiPlot=()=>{
        var i;
        for(i=0;i<document.getElementsByClassName("b").length;i++){
            document.getElementsByClassName("b")[i].style.backgroundColor="white"
            document.getElementsByClassName("b")[i].style.color="#333"
        }
        document.getElementById("multiPlot").style.backgroundColor="#191a1a"
        document.getElementById("multiPlot").style.color="#eee"
        this.setState({multiPlot_bool:true,plot_bool:false,numeric_bool:false,deviceDetails_bool:false, log_bool:false, realtime_bool:false, calibrate_bool:false, alert:false})

    }
    
    deviceDetails=()=>{
        var i;
        for(i=0;i<document.getElementsByClassName("b").length;i++){
            document.getElementsByClassName("b")[i].style.backgroundColor="white"
            document.getElementsByClassName("b")[i].style.color="#333"
        }
        document.getElementById("deviceDetails").style.backgroundColor="#191a1a"
        document.getElementById("deviceDetails").style.color="#eee"
        this.setState({deviceDetails_bool:true, numeric_bool:false,plot_bool:false, multiPlot_bool:false, log_bool:false, realtime_bool:false, calibrate_bool:false, alert:false})
    }
    realtime=()=>{
        var i;
        for(i=0;i<document.getElementsByClassName("b").length;i++){
            document.getElementsByClassName("b")[i].style.backgroundColor="white"
            document.getElementsByClassName("b")[i].style.color="#333"
        }
        document.getElementById("realTime").style.backgroundColor="#191a1a"
        document.getElementById("realTime").style.color="#eee"
        this.setState({realtime_bool:true, numeric_bool:false, plot_bool:false, multiPlot_bool:false, deviceDetails_bool:false, log_bool:false, calibrate_bool:false, alert:false})
    }
    calibrate=()=>{
        var i;
        for(i=0;i<document.getElementsByClassName("b").length;i++){
            document.getElementsByClassName("b")[i].style.backgroundColor="white"
            document.getElementsByClassName("b")[i].style.color="#333"
        }
        document.getElementById("calibration").style.backgroundColor="#191a1a"
        document.getElementById("calibration").style.color="#eee"
        this.setState({calibrate_bool:true, numeric_bool:false, plot_bool:false, multiPlot_bool:false, deviceDetails_bool:false, realtime_bool:false, log_bool:false, alert: false})
    }
    alert=()=>{
        var i;
        for(i=0;i<document.getElementsByClassName("b").length;i++){
            document.getElementsByClassName("b")[i].style.backgroundColor="white"
            document.getElementsByClassName("b")[i].style.color="#333"
        }
        document.getElementById("alert").style.backgroundColor="#191a1a"
        document.getElementById("alert").style.color="#eee"
        this.setState({calibrate_bool:false, numeric_bool:false, plot_bool:false, multiPlot_bool:false, deviceDetails_bool:false, realtime_bool:false, log_bool:false, alert: true})
    }

componentDidMount(){
console.log("Navigator componentDidMount")
document.getElementById("numeric").style.backgroundColor="#191a1a"
document.getElementById("numeric").style.color="#eee"

}


    render() {
        let c=this.props.timestamp_list&&this.props.timestamp_list.length!==0?[...this.props.timestamp_list].reverse():[]
        let d=this.props.mean&&this.props.mean.length!==0?[...this.props.mean].reverse():[]
        // console.log(c, d)
        // console.log('Navigator render',c,d,this.state.timestamp_list,this.state.mean);
        let a,b=null;
        // console.log("The passed down props are", this.props)
        if (this.props.navbar!=="home")
        {
            return (<div></div>);
        }
        else if (this.state.realtime_bool) {
            a=(
                <RealTime></RealTime>
            )
        }
        else if (this.state.numeric_bool) {
            a=(<Numeric name={this.props.name} timestamp_list={this.props.timestamp_list} mean={this.props.mean} mode={this.props.mode} deviceID={this.props.deviceID} auth={this.props.auth}></Numeric>)
        }
        else if (this.state.deviceDetails_bool) {
            a=( <DeviceDetails deviceID={this.props.deviceID} auth={this.props.auth} location={this.props.location}/>)
        }
        else if (this.state.plot_bool){                
            a=(<>
                <Plot name={this.props.name} timestamp_list={c} mean={d} mode={this.props.mode} deviceID={this.props.deviceID} auth={this.props.auth}/>
            </>)
        }
        else if (this.state.multiPlot_bool){                
            a=(<MultiPlot name={this.props.name} deviceList={this.props.deviceList} auth={this.props.auth}/>)
        }
        else if (this.state.realtime_bool){
            a=( <RealTime></RealTime>)
        }
        else if (this.state.calibrate_bool){
            a=(<Calibration timestamp_list={c} mean={d} deviceID={this.props.deviceID}></Calibration>)
        }
        else if(this.state.alert){
            a=(<Alert/>)
        }

        
        return (
        <div>
            <div className="first">
                <div className="navigB">
                    <Button type="button" id="numeric" className="b" onClick={this.numeric}>Numeric</Button>
                    <Button type="button" id="plot" className="b" onClick={this.plot}>Plot</Button>
                    {/* <Button id="b" onClick={this.realtime}>Realtime Plot</Button> */}
                    <Button type="button" id="deviceDetails" className="b" onClick={this.deviceDetails}>Device Details</Button>
                    <Button type="button" id="multiPlot" className="b" onClick={this.multiPlot}>Multiple Plots</Button>
                    {/* <Button type="button" id="calibration" className="b" onClick={this.calibrate}>Calibration</Button>
                    <Button type="button"  id="alert" className="b" onClick={this.alert}>Alerts</Button> */}
                    <IssueModal deviceID={this.props.deviceID} deviceList={this.props.deviceList} auth={this.props.auth}/>
                </div>
                    <hr></hr>{a}
            </div>
</div>

        )
    }
}

export default Navigator;
