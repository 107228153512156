import React, { Component } from 'react'

export const MyContext = React.createContext();

export default class DashboardContext extends Component {
    
    state={
        name:"Rishabh",
        list:"rishabh",
        // UpdateData:this.UpdateData
        
    }
    updateData = (data) => {
        this.setState({
            name:data
        })
        console.log("hi");
        
    }
    render() {
        const contextValue = {
            context: this.state,
            handleData:e=>this.updateData(e)
        }
        return (
            <MyContext.Provider value={contextValue}
            //     state:this.state,
            //     UpdateData:()=>{
            //         this.setState({
            //             list:"lol",
                        
            //         })
            //     }
            // }
        >
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

