import React, { Component } from 'react'
// import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import image from '../../images/cwc.png';
import './Profile.css';
import { Button } from 'react-bootstrap';
import  { Redirect,Link } from 'react-router-dom'

class Profile extends Component {
    constructor(props){
        super(props);
        console.log(this.props.location.aboutProps)
    }

    render() {
        if(!this.props.location.aboutProps){
            return(
                <Redirect to="/" />
            )
        }
        return (
            <div className="profile">
                {/* <Navbar/> */}

                {/* Sidebar to be implemented afterwards*/}


                {/* <Sidebar/> */}
                <div className="user-profile">
                <Button id="permit">Request Permission</Button>
                    <div className="user-image">
                        <img className="cwc" src={image} alt="cwc" />
                    </div>
                    <div className="user-info">
                        <div className="username">
                            <p>Central Water Commission</p>
                        </div>
                        <div className="info">
                            <p className="head">Username</p>
                            <p className="name">central.water.commission</p>
                        </div>
                        <div className="email">
                            <p className="head">Email</p>
                            <p className="name">abc@xyz.com</p>
                        </div>
                        <div className="department">
                            <p className="head">Department</p>
                            <p className="name">Water Quality</p>
                        </div>
                        <div className="role">
                            <p className="head">Role</p>
                            <p className="name">Client</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;
