import React, { Component } from 'react'
import axios from 'axios'
export default class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: "",
            returnStatus:''
        }
        this.handleSubmitUser = this.handleSubmitUser.bind(this);


    }
    handleSubmitUser = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        // console.log(e.target.value);
        console.log(this.state.selectedOption)
        console.log(data.get("description"));
        console.log(data.get("network"));
        let userData = {
            username: data.get("username"),
            network: data.get("network"),
            description: data.get("description"),
            role: this.state.selectedOption
        }

        axios.post('http://0.0.0.0:5000/adminUserinfo', userData).then(response => {
            this.setState({returnStatus:response.data.text})
            // console.log(response.data.text);

        })
    };
    handleOptionChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        })
    }
    render() {
        let a = null
        if(this.state.returnStatus){
            a = (
                <h3>User has been Added</h3>
            )
        }
        return (
            <div>
                <form onSubmit={this.handleSubmitUser}>
                    <label htmlFor="username">Username: </label>
                    <input type="text" name="username" />
                    <br />
                    <br />
                    <label htmlFor="network">Network: </label>
                    <input type="text" name="network" />
                    <br />
                    <br />
                    <label htmlFor="description">Description: </label>
                    <input type="text" name="description"></input>
                    <br />
                    <br />
                    <h2>Roles</h2>
                    <fieldset>
                        <input
                            type="radio"
                            name="Client"
                            id="1"
                            value="Client"
                            checked={this.state.selectedOption === "Client"}
                            onChange={this.handleOptionChange}
                        />
                        Client
              <br />
                        <input
                            type="radio"
                            name="Operations Admin"
                            id="2"
                            value="Operations Admin"
                            checked={this.state.selectedOption === "Operations Admin"}
                            onChange={this.handleOptionChange}
                        />
                        Operations Admin<br></br>
                        <input
                            type="radio"
                            name="Software Admin"
                            id="3"
                            value="Software Admin"
                            checked={this.state.selectedOption === "Software Admin"}
                            onChange={this.handleOptionChange}
                        />
                        Software Admin
            </fieldset>
                    <br />
                    <input type="submit" value="Add User" />
                </form>
                {a}
            </div>
        )
    }
}
