import React, { Component } from 'react'
import './RealTime.css';
import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

var dps = [{x: 1, y: 10}, {x: 2, y: 13}, {x: 3, y: 18}, {x: 4, y: 20}, {x: 5, y: 17},{x: 6, y: 10}, {x: 7, y: 13}, {x: 8, y: 18}, {x: 9, y: 20}, {x: 10, y: 17}];   //dataPoints.
var xVal = dps.length + 1;
var yVal = 15;
var updateInterval = 10000;

class RealTime extends Component{

    constructor() {
		super();
		this.updateChart = this.updateChart.bind(this);
	}
	componentDidMount() {
		setInterval(this.updateChart, updateInterval);
	}
	updateChart() {
		yVal +=  Math.round(5 + Math.random() *(-5-5));
		dps.push({x: xVal,y: yVal});
		xVal++;
		if (dps.length >  10 ) {
			dps.shift();
		}
		this.chart.render();
	}
    
    render(){
        const options = {
			title :{
			},
			data: [{
				type: "line",
				dataPoints : dps
			}]
		}
        return(
            <div className="backdrop">
                <div className="plot">
                    <CanvasJSChart options={options}
                     onRef={ref => this.chart = ref}
                    />
                </div>
            </div>
        );
    }
}

export default RealTime;
