import {Modal} from "react-bootstrap"
import {Button} from "react-bootstrap"
import React, { useState, useEffect } from 'react';

function ImageModal(props) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    useEffect(() => {
        if(document.getElementsByClassName("modal-dialog")[0]){
            document.getElementsByClassName("modal-dialog")[0].style.maxWidth="80%"
            document.getElementsByClassName("modal-dialog")[0].style.width="auto"
            document.getElementsByClassName("modal-dialog")[0].style.display="table"

        }
    });
    
    return (
        <>
        {props.src?(<img onClick={handleShow} src={props.src} style={{"max-width":"100%"}}/>):<div/>}
        <Modal style={{opacity:1, "background-color": "rgba(19,19,19,0.8)", "z-index":1000, "padding-top":"30vh"}} show={show} onHide={handleClose}>
            <Modal.Body style={{"padding":"0px"}}><img src={props.src} style={{"max-width":"100%"}}></img></Modal.Body>
        </Modal>
        </>)
}
export default ImageModal