import React, { Component,useContext,useState,setState } from 'react'
import Logout from './Logout'
import axios from "axios";
import {Button} from "react-bootstrap"
// import Dash from '../../../../../Chandranshu Work/react_projet/src/components/Dash/Dash';
// import {MyContext} from '../../context/DashboardContext'
import Landing from '../DashboardLanding/Landing'
import {Redirect} from 'react-router-dom'
import Amplify ,{Auth} from 'aws-amplify'
import loading from "./loading.gif"
import './UserInfo.css'
import testUrl from "../../config"
import AdminLanding from '../AdminPanel/AdminLanding/AdminLanding';

export const MyDashboardContext = React.createContext();

export default class UserInfo extends Component {
    constructor(props) {
        super(props)
        // console.log("Userinfo has been opened")
        this.state = {
            error:false,
            b:"",
            LoD:"",
            timestamp_list:[],
            mean:"",
            outlier_list:"",
            navbar:"home",
            auth:this.props.auth,
            locations:[]
        }
        this.state2={}
    }
    async componentDidMount() {
        let currentUser= await Auth.currentUserInfo()
        if(currentUser)
        {
            axios.get(testUrl.serverUrl+"api/query/user/"+currentUser.username,{ headers:{ "x-access-token":this.props.auth.token}}).then((res)=>{
                console.log(res)
                var i, arr=[]
                for(i=0;i<res.data.assigned_devices.length;i++)
                        arr.push(res.data[res.data.assigned_devices[i]])
                this.setState({
                    timestamp_list:res.data.timestamp,
                    mean:res.data.mode,
                    LoD:res.data.assigned_devices,
                    deviceID:res.data.deviceID,
                    locations:arr
                })
            }).catch(error=>{      
                // console.log(error)
                if(error.toString()==="Error: Network Error")
                {
                    this.setState({error:true})
                    let dummy
                    document.getElementsByClassName("loadingDiv")?document.getElementsByClassName("loadingDiv")[0].style.backgroundColor="#ccccff":dummy=1
                }
                return (<Redirect to={{ pathname: "/" }}/>)
            })
        }
    }
    notifyAdmin(){
        axios.get(testUrl.serverUrl+"api/email/"+this.props.auth.user.username
        ,{ headers:{ "x-access-token":this.props.auth.token}}
        ).then((res)=>{
            // console.log(res)
            this.setState({b:"The admin has been notified. You will be assigned your devices shortly"})
        })
    }
    render() {
        if(!this.props.auth.isAuthenticated)
        {
            return (<Redirect to={{ pathname: "/" }}/>)
        }
        // console.log(this.state.auth)
        if (this.state.LoD) {
            return (
                <MyDashboardContext.Provider value={this.state}>
                    <Landing></Landing>
                </MyDashboardContext.Provider>
            )
        }
        let a=(<img id="loadingImg" src={loading} alt="loading" />)
        if(this.state.error){
            a=(<div className="loadingText">
                <h1>You have no devices assigned. Request an admin for access to devices.</h1>
                <Button className="bigNotifyButton" onClick={()=>{this.notifyAdmin()}}>Notify admin</Button><Logout auth={this.props.auth} fromPage={true}/>
            </div>)
        }
        return (
            <div className="loadingDiv">
                {a}
                <h1>{this.state.b}</h1>
                {this.props.auth.user===null?setTimeout("location.reload(true)",10):""}
            </div>
        )
    }
}
