import React, { Component } from 'react';
import Plot from '../Backdrop/Plot'
import testUrl from "../../config"
import axios from "axios"
import './MultiPlot.css'

class MultiPlot extends Component {
    constructor(props){
        super(props)
        this.state={
            data1:[]
        }
        let arr=this.props.deviceList.slice(0,8)
        axios.post(testUrl.serverUrl+"api/query/graph",{"device_list":arr}).then(res=>{
            console.log(res.data)
            this.setState({
                data1:res.data
            })
        }).catch(err=>{
            this.setState({
                data1:[]
            })
        })
    }
    componentDidMount(){
        
    }
    render(){
        let plots=this.state.data1.length!==0?this.state.data1.map(dt=>{
            return(<Plot name={this.props.name} deviceID={dt.deviceID} deviceList={this.props.deviceList} timestamp_list={dt.timestamp} mean={dt.mode} mode={dt.mode} auth={this.props.auth} dontShowOptions={true}></Plot>)})
            :""
        console.log(plots)
        var i
        let styledPlot=[]
        for(i=0;i<plots.length/2;i++){
            styledPlot.push(<tr><td>{plots[2*i]}</td><td>{plots[2*i+1]?plots[2*i+1]:""}</td></tr>)
        }
        return(<table className="plotTable">{styledPlot}</table>)
    }
}

export default MultiPlot