import React, { Component } from "react";
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios'
import { Button } from "react-bootstrap"
import { CSVLink, CSVDownload } from "react-csv";
// import View from '../../View/View'
import '../../View/View.css'
import "../../Backdrop/Plot"
import "./Numeric.css";
import ApolloClient from 'apollo-boost'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks';
import testUrl from "../../../config"
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


let table
let flag = true
export default class numeric extends Component {

  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      startDate:"",
      endDate:"",
      redirect: false,
      timestamp: null,
      outlier: null,
      deviceID: this.props.deviceID,
      dataType: "raw",
      shouldDownload: false,
      arr: [],
      filename: this.props.deviceID + " Latest",
      b: ""
    };
    this.stat = "cleaned"
    this.baseState = this.state
    this.handleSubmit = this.handleSubmit.bind(this);
    this.Download = this.Download.bind(this);
    this.meanEvent = this.meanEvent.bind(this);
    this.modeEvent = this.modeEvent.bind(this);
    // this.rawEvent=this.rawEvent.bind(this);
    this.cleanedEvent = this.cleanedEvent.bind(this);
    this.settingChanged = this.settingChanged.bind(this);
  }
  Download = param => e => {
    console.log("Downloading")
    e.preventDefault()
    let query
    let startDate = document.getElementsByName("from")[0].value
    let endDate = document.getElementsByName("to")[0].value
    let dataType = document.getElementsByName("select")[0].value
    console.log(startDate,endDate,dataType)
    let a = []
    let dateEntered = false
    a.push(["Timestamp list", this.stat === "SoC" ? "SoC" : (this.stat === "RSSI" ? "RSSI" : "Water Level (in metres)")])
    let rs = "special" // range/special
    let abc
    if (dataType === "Default Data") {
      rs = "range"
      abc = "\0"
    }
    else if (dataType === "RLD Daily Average")
      abc = "/DailyAverage"
    else if (dataType === "RLD Hourly Data")
      abc = "/HourlyData"
    else if (dataType === "RLD Monthly Maximum and Minimum")
      abc = "/Monthly"

    if (startDate === "" || endDate === "") {
      this.setState({ b: "Please select a valid date range to download the data" })
      setInterval(()=>{this.setState({ b: "" })},5000)
    }
    else {
      query = testUrl.serverUrl + "api/query/device/" + rs + "/" + this.props.deviceID + "/" + startDate + "/" + endDate + abc
      dateEntered = true
    }
    let keyWord = "mode"
    if (this.stat !== "cleaned") {
      query = testUrl.serverUrl + "api/query/device/raw/" + rs + "/" + this.props.deviceID + "/" + this.stat + "/" + startDate + "/" + endDate + abc
      keyWord = (this.stat === "RSSI" || this.stat === "SoC") ? "rawValue" : (this.stat === "mean" ? "mean" : "mode")
    }
    if (dateEntered) {
      axios.get(query, { headers: { "x-access-token": this.props.auth.token } }).then(res => {
      console.log(res)
      this.setState({
        redirect:true,
        timestamp:res.data.timestamp,
        outlier:res.data[keyWord],
        filename:this.props.deviceID+" "+startDate + " " + endDate + " " + dataType
      })
      document.getElementById("Download2").click()
      }).catch(error => {
        if (error.toString() === "Error: Network Error") {
          this.setState({ b: "We may not have data in the date range you specified. Please specify a different range" })
          setInterval(()=>{this.setState({ b: "" })},5000)
        }
      })
    }
  }

  meanEvent = param => e => {
    var i;
    for (i = 0; i < 5; i++) {
      document.getElementsByClassName("b2")[i].style.backgroundColor = "rgb(224,224,224)"
      document.getElementsByClassName("b2")[i].style.color = "#333"
    }
    document.getElementById("meanB").style.backgroundColor = "#4f4f4f"
    document.getElementById("meanB").style.color = "white"
    // console.log("meaning")
    this.stat = "mean"
    this.settingChanged()
  }
  modeEvent = param => e => {
    var i;
    for (i = 0; i < 5; i++) {
      document.getElementsByClassName("b2")[i].style.backgroundColor = "rgb(224,224,224)"
      document.getElementsByClassName("b2")[i].style.color = "#333"
    }
    document.getElementById("modeB").style.backgroundColor = "#4f4f4f"
    document.getElementById("modeB").style.color = "white"
    // console.log("modeing")
    e.preventDefault();
    this.stat = "mode"
    this.settingChanged()
  }
  socEvent = param => e => {
    var i;
    for (i = 0; i < 5; i++) {
      document.getElementsByClassName("b2")[i].style.backgroundColor = "rgb(224,224,224)"
      document.getElementsByClassName("b2")[i].style.color = "#333"
    }
    document.getElementById("socB").style.backgroundColor = "#4f4f4f"
    document.getElementById("socB").style.color = "white"
    this.stat = "SoC"
    this.settingChanged()
  }
  rssiEvent = param => e => {
    var i;
    for (i = 0; i < 5; i++) {
      document.getElementsByClassName("b2")[i].style.backgroundColor = "rgb(224,224,224)"
      document.getElementsByClassName("b2")[i].style.color = "#333"
    }
    document.getElementById("rssiB").style.backgroundColor = "#4f4f4f"
    document.getElementById("rssiB").style.color = "white"
    this.stat = "RSSI"
    this.settingChanged()
  }
  cleanedEvent = param => e => {
    var i;
    for (i = 0; i < 5; i++) {
      document.getElementsByClassName("b2")[i].style.backgroundColor = "rgb(224,224,224)"
      document.getElementsByClassName("b2")[i].style.color = "#333"
    }
    document.getElementById("cleanedB").style.backgroundColor = "#4f4f4f"
    document.getElementById("cleanedB").style.color = "white"
    this.stat = "cleaned"
    this.settingChanged()
  }

  settingChanged = () => {
    // console.log("function called")
    if (this.stat === "cleaned") {
      axios.get(testUrl.serverUrl + "api/query/device/" + this.props.deviceID, { headers: { "x-access-token": this.props.auth.token } }).then((res) => {
        console.log(res)
        this.setState({
          redirect: true,
          timestamp: res.data.timestamp,
          outlier: res.data.mode
        })
      }).catch(error=>{
        this.setState({
          redirect: true,
          timestamp: [],
          outlier: []
        })
      })
    }
    else {
      axios.get(testUrl.serverUrl + "api/query/device/raw/" + this.props.deviceID + "/" + this.stat, { headers: { "x-access-token": this.props.auth.token } }).then((res) => {
        console.log(res)
        this.setState({
          redirect: true,
          timestamp: res.data.timestamp,
          outlier: (this.stat === "RSSI" || this.stat === "SoC") ? (res.data.rawValue) : (this.stat === "mean" ? res.data.mean : res.data.mode)
        })
      })
    }
  }
  componentDidMount() {
    document.getElementById("cleanedB").style.backgroundColor = "#4f4f4f"
    document.getElementById("cleanedB").style.color = "white"
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    // this.cleanedEvent()()
    var i
    for (i = 0; i < 5; i++) {
      document.getElementsByClassName("b2")[i].style.backgroundColor = "rgb(224,224,224)"
      document.getElementsByClassName("b2")[i].style.color = "#333"
    }
    document.getElementById("cleanedB").style.backgroundColor = "#4f4f4f"
    document.getElementById("cleanedB").style.color = "white"
    this.stat = "cleaned"
    // console.log(newProps)
  }
  handleSubmit = e => {
    e.preventDefault();
    let startDate = e.target.from.value;
    let endDate = e.target.to.value;
    let dataType = e.target.select.value;
    let rs = "special" // range/special
    let abc = "\0"
    // console.log(dataType, this.stat)
    if (dataType === "Default Data") {
      rs = "range"
      abc = "\0"
    }
    else if (dataType === "RLD Daily Average")
      abc = "/DailyAverage"
    else if (dataType === "RLD Hourly Data")
      abc = "/HourlyData"
    else if (dataType === "RLD Monthly Maximum and Minimum")
      abc = "/Monthly"

    if (this.stat === "cleaned") {
      axios.get(testUrl.serverUrl + "api/query/device/" + rs + "/" + this.props.deviceID + "/" + startDate + "/" + endDate + abc, { headers: { "x-access-token": this.props.auth.token } }).then(res => {
        console.log(res)
        this.setState({
          redirect: true,
          timestamp: res.data.timestamp,
          outlier: res.data.mode
        })
      }).catch(error => {
        if (error.toString() === "Error: Network Error") {
          this.setState({ b: "We may not have data in the date range you specified. Please specify a different range" })
          setInterval(()=>{this.setState({ b: "" })},5000)
        }
      })
    }
    else {
      axios.get(testUrl.serverUrl + "api/query/device/raw/" + rs + "/" + this.props.deviceID + "/" + this.stat + "/" + startDate + "/" + endDate + abc, { headers: { "x-access-token": this.props.auth.token } }).then(res => {
        console.log(res)
        this.setState({
          redirect: true,
          timestamp: res.data.timestamp,
          outlier: (this.stat === "RSSI" || this.stat === "SoC") ? (res.data.rawValue) : (this.stat === "mean" ? res.data.mean : res.data.mode)
        })
      }).catch(error => {
        if (error.toString() === "Error: Network Error") {
          this.setState({ b: "We may not have data in the date range you specified. Please specify a different range" })
          setInterval(()=>{this.setState({ b: "" })},5000)
        }
      })
    }
  }

  componentWillReceiveProps() {
    this.setState({ redirect: false })
  }

  render() {
    this.copy=[]
    if (this.state.redirect) {
      let timestamp_list = this.state.timestamp
      let outlier = this.state.outlier
      const listItems = timestamp_list.map((number, index) => {
        let outlierContent = outlier ? outlier[index] : []
        let sampleData = {
          timestamp: number,
          outlier: outlierContent
        }
        this.copy.push(sampleData)
      })
      const columns = [
        {
          Header: "Time Stamp",
          accessor: "timestamp"
        },
        {
          Header: this.stat === "SoC" ? "SoC" : (this.stat === "RSSI" ? "RSSI" : "Water Level (in metres)"),
          accessor: "outlier"
        },
      ];
      table = (
        <ReactTable
          data={this.copy.filter(elem => { return elem.outlier !== null })}
          columns={columns}
          defaultPageSize={10} className="myTableClass" />
      )
    }
    else {
      let timestamp_list = this.props.timestamp_list
      let outlier = this.props.mean ? this.props.mean : ""
      let listItems = timestamp_list.map((number, index) => {
        let outlierContent = outlier?outlier[index]:[]
        let sampleData = {
          timestamp: number,
          outlier: outlierContent
        }
        this.copy.push(sampleData)

      })
      const columns = [
        {
          Header: "Time Stamp",
          accessor: "timestamp"
        },
        {
          Header: this.stat === "SoC" ? "SoC" : (this.stat === "RSSI" ? "RSSI" : "Water Level (in metres)"),
          accessor: "outlier"
        },
      ]
      table = (
        <ReactTable
          data={this.copy.filter(elem => { return (elem.outlier !== null) })}
          columns={columns}
          defaultPageSize={10} />
      )
    }
    console.log(this.copy)
    return (
      <div className="bigDiv">
        <div className="numericB">
          <Button id="cleanedB" className="b2" onClick={this.cleanedEvent()}>Cleaned</Button>
          <Button id="meanB" className="b2" onClick={this.meanEvent()}>Mean</Button>
          <Button id="modeB" className="b2" onClick={this.modeEvent()}>Median</Button>
          <Button id="socB" className="b2" onClick={this.socEvent()}>SoC</Button>
          <Button id="rssiB" className="b2" onClick={this.rssiEvent()}>RSSI</Button>
        </div>
        <br/>
        <div className="backdrop">
          <div className="numeric">
            {table}
          </div>
        </div>

        <div className="view">
          <form onSubmit={this.handleSubmit}>
            <br />
            <h3>View or Download Data</h3>
            <p>From :</p><input id="calendar" type="date" name="from" required />
            <p>To :</p> <input id="calendar" type="date" name="to" required />
            <select className="select" name="select" required>
              <option value="">Select Intervals</option>
              <option name="cleaned" value="Default Data" >Default Data</option>
              {/* <option name="rld" value="River Level Data (RLD)">River Level Data (RLD)</option> */}
              <option name="hourly" value="RLD Hourly Data" >RLD Hourly Data</option>
              <option name="average" value="RLD Daily Average" >RLD Daily Average</option>
              {/* <option name="five" value="RLD Daily at 8:00 13:00 18:00">RLD Daily at 8:00 13:00 18:00</option> */}
              <option name="maxmin" value="RLD Monthly Maximum and Minimum">RLD Monthly Maximum and Minimum</option>
            </select>
            <div>
              <button name="View" value="View" id="lol">View</button>
                <button id="Download" name="Download" value="Download" onClick={this.Download()}>Download</button>
                         <ExcelFile filename={this.state.filename} element={<button id="Download2">D2</button>}>
                          <ExcelSheet name={this.stat} data={this.copy}>
                            <ExcelColumn label="Timestamp" value="timestamp"/>
                            <ExcelColumn label={this.stat} value="outlier"/>
                          </ExcelSheet>
                        </ExcelFile>
              {this.state.b}
            </div>
          </form>
        </div>
      </div>
    );
  }
}