import React, { Component } from 'react'
import axios from 'axios'
export default class AssignDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: "",
        }
        this.handleSubmitAssignDevice = this.handleSubmitAssignDevice.bind(this)

    }
    handleOptionChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        })
    }
    handleSubmitAssignDevice = (e) => {
        e.preventDefault()
        const data = new FormData(e.target);

        console.log(data.get("deviceId"));
        let assignDeviceData = {
            username: data.get("username"),
            device: data.get("deviceId"),
            network:data.get('networkID')
            
        }
        console.log(assignDeviceData);

        axios.post('http://0.0.0.0:5000/adminAssigndevice', assignDeviceData).then(response => {
            // this.setState({returnStatus:response.text})
            console.log(response.data.text);

        })


    }
    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmitAssignDevice}>
                    <label htmlFor="username">Username: </label>
                    <input type="text" name="username" />
                    <br />
                    <br />
                    <label htmlFor="deviceId">Device Id: </label>
                    <input type="text" name="deviceId" />
                    <br />
                    <br />
                    <label htmlFor="networkID">Network Id: </label>
                    <input type="text" name="networkID" />
                    <br />
                    <br />
                    <input type="submit" value="Assign Device" />
                </form>
            </div>
        )
    }
}
