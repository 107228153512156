import React, { Component } from 'react';
import axios from 'axios'
import DeviceIssue from './DeviceIssue'
import {Button} from '@material-ui/core'

export default class AdminIssues extends Component {
    constructor(props) {
        super(props);

        this.state = {sampleData:[],particularData:null,};
    }
componentDidMount(){
    let data={
        text:"rishabh"
    }
    axios.post("http://0.0.0.0:5000/adminIssues",data).then(res=>{
    this.setState({
        sampleData:res.data.deviceList,
    })
    })

}
GotoDownloadpage=(number,e)=>{
       this.setState({
           particularData:number,
       })
   }

    render() {
        let someList= <DeviceIssue data={this.state.particularData}></DeviceIssue>
        const listItems = this.state.sampleData.map((number)=>{
           let boundItemClick = this.GotoDownloadpage.bind(this, number);
            return (<tr>
              <td>
              {number.deviceID}
             </td>&emsp;
              <td>{number.Title}</td>&emsp;
              <td>{number.DateCreated}</td>&emsp;
             <td>{number.Description}</td>&emsp;
             <Button onClick={boundItemClick} variant="outlined" color="secondary">Track this Issue</Button>
               <br></br>
              {number.chats.map(m=>(
                  <div>
                 {m.dateCreated}
                 {m.comment}</div>
              ))}
           </tr>)
  
           });

        return (
            <div>
                   {listItems}
                   {someList}
            </div>

        )
    }
}
