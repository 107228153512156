import React, { Component } from 'react'
import axios from 'axios'
// const UserList = null
export default class EditUser extends Component {
    constructor(props){
      super(props);
      this.state={
        List:null,
      }

      axios.get("http://0.0.0.0:5000/UserInfo").then(res=>{
              console.log(res.data.UserList);
              const userInfo = res.data.UserList
              this.setState({
                List:userInfo
              },()=>{
                console.log(this.state.List);
              })


            })


    }
    componentDidMount(){



    }

    render() {
      if (this.state.List){
        return (<div>
    {this.state.List.map((item, index) => (
        <p>{item.username}  {item.role}   {item.added_devices}  {item.network}</p>
    ))}
    </div>);
      }


        return (
            <div>

		</div>
        )
    }
}
