import React, { Component } from 'react'
import c3 from 'c3';

class Chart extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        xAxis:['x', '2013/01/01 16:13:40', '2013/01/02 16:13:40', '2013/01/03 16:13:40', '2013/01/04 16:13:40', '2013/01/05', '2013/01/06 16:13:40'],
        column1: ['data1', 30, 200, 100, 400, 150, 250],
        column2: ['data2', 50, 20, 10, 40, 15, 25],
      };
  
      this.changeData = this.changeData.bind(this);
    }
  
    renderChart() {
      c3.generate({
        bindto: "#chart1",
        data: {
          x:'x',
          xFormat: '%Y/%m/%d %H:%M:%S',
          columns: [this.state.xAxis,this.state.column1, this.state.column2],
        },
        axis: {
          x: {
              type: 'timeseries',
              tick: {
                  format: '%Y/%m/%d %H:%M:%S'
              }
          }
      },
      size: {
        height: 440,
        width: 1100
    },
      });
    }
  
    componentDidMount() {
      this.renderChart();
    }
  
    componentDidUpdate() {
      this.renderChart();
    }
  
    // Changes data to something arbitrary on button click
    changeData() {
      this.setState({
        column1: ['data1', 70, 120, 30, 300, 230, 300],
        column2: ['data2', 100, 120, 50, 140, 150, 80],
      });
    }
  
    render() {
      return (
        <div>
          <div id="chart1"></div>
          <button onClick={this.changeData}>Change</button>
        </div>
      );
    }
  }
  export default Chart;