import {Modal} from "react-bootstrap"
import {Button} from "react-bootstrap"
import React, { useState, useEffect } from 'react';
import "./AssignDevicesModal.css"
import axios from "axios"
import testUrl from "../../../config"

function CreateIssueModal(props) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [a, setA]=useState("")

    const handleSubmit = (event) => {
        event.preventDefault()
        var i, arr=[]
        for(i=0;i<props.listOfDeviceNames.length;i++)
        {
            if(event.target[props.listOfDeviceNames[i]].checked){
                arr.push(props.listOfDeviceNames[i])
            }
        }
        console.log(event.target.userName.value)
        axios.post(testUrl.serverUrl+"/api/admin/mapping",{"username":event.target.userName.value,"assigned_devices":arr},
{ headers:{ "x-access-token":props.auth.token}}
).then(res=>{
            console.log(res)
            setA("Devices successfully assigned")
        })
    }
    let users=props.listOfUsers.map(user=>{return (<option>{user}</option>)})
    let devices=props.listOfDeviceNames.map(device=>{
        return (<div className="ADMcheckBoxes">
            <input type="checkbox" id={device} name={device} value={device}/>
            <label for={device}>{device}</label>
        </div>)
    })
    return (
        <>
            <Button className="assignDevicesButton" onClick={handleShow}>Assign Devices to user</Button>
            <Modal style={{"opacity":"1", "background-color": "rgba(19,19,19,0.8)", "padding-top":"30vh"}} show={show} onHide={handleClose}>
                <Modal.Title>
                    Assign devices to user
                </Modal.Title>
                <Modal.Body id="assignModal">
                    <form onSubmit={handleSubmit}>
                        Select user <select id="userName" required>
                            {users}
                        </select>
                        {devices}
                        <button>Submit</button>
                    </form>
                    <br/>
                    <div>{a}</div>
                </Modal.Body>
            </Modal>
        </>
        )
}
export default CreateIssueModal