import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from 'axios'
import EditUser from './EditUser/EditUser'
import AddDevice from './AddDevice/AddDevice'
import AddUser from './AddUser/AddUser'
import AssignDevice from './AssignDevice/AssignDevice'
import Logout from '../../Login/Logout'
import AdminIssues from './AdminIssues/AdminIssues'
import Navbar2 from "../../Navbar/Navbar2"
import "./AdminLanding.css"
import CreateUserModal from "./CreateUserModal"
import AssignDevicesModal from "./AssignDevicesModal"
import DeviceAttributesModal from "./DeviceAttributesModal"
import CalibrationEqnModal from "./CalibrationEqnModal"
import EditCalibrationEqnModal from "./EditCalibrationEqnModal"

import testUrl from "../../../config"
import  { Redirect,Link } from 'react-router-dom'

export default class AdminLanding extends Component {
    constructor(props) {
        super(props);
    // this.users=[]
    
    this.state = {
      option: "user",
      data1:[],
      data2:[],
      search:'',
      calibEqs:[]
    };

    axios.get(testUrl.serverUrl+"api/admin/create"
,{ headers:{ "x-access-token":this.props.auth.token}})
    .then(res=>{
      this.setState({data1:res.data})
    })
    
    axios.get(testUrl.serverUrl+"api/admin/device/attributes",{ headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
        this.setState({data2:res.data})
    })

    axios.get(testUrl.serverUrl+"api/client/cali",{ headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
      console.log(res)
      let a=res.data.map(device=>{return(device.calibEq)})
      console.log(a)
      this.setState({calibEqs:a})
    })
  }
  
  
      componentWillMount(){
  }

  typing= e =>{
    this.setState({search:e.target.value})
  }
  getUpdatedData(){
    axios.get(testUrl.serverUrl+"api/admin/create"
,{ headers:{ "x-access-token":this.props.auth.token}})
    .then(res=>{
      this.setState({data1:res.data})
    })
   
  }

  render() {
    if(!this.props.auth.isAuthenticated || this.props.auth.user.attributes.profile==="Client")
      return (<Redirect to={{ pathname: "/" }}/>)
    
    let listOfUsers=this.state.data1.map(user=>{
        return user.username
    })
    let listOfDevices=this.state.data2.map((device,index)=>{
    return(<div><div><b>Device ID:</b>{device.device_id?device.device_id:""}</div>
      <div><b>Device Network:</b>{device.network?device.network:""}</div>
      <div><b>Device River:</b>{device.river?device.river:""}</div>
      <div><b>Device Location:</b>{device.location?device.location.toString():""}</div>
      <div><b>Device ToS:</b>{device.sensor?device.sensor.toString():""}</div>
      <div><b>Device Measuring Parameter:</b>{device.measParam?device.measParam.toString():""}</div>
      <div><b>Device Coordinates:</b>{device.coordinates?"("+device.coordinates[device.coordinates.length-2]+","+device.coordinates[device.coordinates.length-1]+")":""}</div>
      <div><b>Device Calibration Equation:</b>{this.state.calibEqs[index]?this.state.calibEqs[index]:""}</div>
    <div><b>Device Image:</b>{device.deviceImage?(<img className="devImg" src={device.deviceImage}/>):""}</div>
      {this.state.calibEqs[index]?<EditCalibrationEqnModal auth={this.props.auth} deviceID={device.device_id}/>:""}
      <br/>
      </div>)
    })
    let listOfDeviceNames=this.state.data2.map(device=>{
        return device.device_id
    })
    let dataCards=this.state.data1&&this.state.data1.map(user=>{
      if(this.state.search!==""&&user.username.toLowerCase().indexOf(this.state.search)===-1)
        return null
      let deviceList=[]
      if(user.assigned_devices)
      {
       deviceList=user.assigned_devices.map(device=>{
          return(<div>{device}</div>)
        })
      }
      return(
      <div className="card">
      <div className="cardDetails">
        <h4>Client Details</h4>
        <div className="detailFlex">
          <div className="detailKeys">
            <div>Name</div>
            <div>Post</div>
            <div>Office</div>
            <div>Total Devices</div>
          </div>
          <div className="detailValues">
            <div>{user.username?user.username:""}</div>
            <div>{user.role?user.role:""}</div>
            <div>{user.network?user.network:""}</div>
            <div>{user.assigned_devices?user.assigned_devices.length:0}</div>
          </div>
        </div>
      </div>
      <div className="cardList">
        <h4>List of devices</h4>
        <div className="cardDevices">
          {deviceList}
        </div>
      </div>
    </div>)
    })
    return (
      <div>
        <Navbar2 auth={this.props.auth}/>
        <div className="adminBigDiv">
        <Button onClick={()=>{this.setState({option:"user"})}}>Users list</Button>
        <Button onClick={()=>{this.setState({option:"device"})}}>Devices list</Button><br/>
        <input type="text" placeholder="Search by client name" id="clientSearch" onChange={this.typing}></input><select>
          <option>Filters</option>
        </select>
        <br/>
        Client list and number of devices to assign
        <CalibrationEqnModal auth={this.props.auth} listOfDeviceNames={listOfDeviceNames}/>
        <DeviceAttributesModal auth={this.props.auth} listOfDeviceNames={listOfDeviceNames}/>
        <AssignDevicesModal auth={this.props.auth} listOfUsers={listOfUsers} listOfDeviceNames={listOfDeviceNames}/>
        <CreateUserModal auth={this.props.auth}/>
        <hr/>
        {this.state.option==="user"?
        (<div className="cards">
        {dataCards}
        </div>):listOfDevices }
      </div>
      <br />
      </div>
    );
  }
}
