import React, { Component } from 'react';
import { Button } from "react-bootstrap"
import axios from 'axios'
import {Line} from 'react-chartjs-2';
import '../View/View.css'
import './Plot.css'
import { FaXRay } from 'react-icons/fa';
import * as Zoom from "chartjs-plugin-zoom"
import testUrl from "../../config"
import { Chart } from "react-google-charts";

export default class Plot extends Component {
    constructor(props) {
        console.log(testUrl.serverUrl)
        super(props);
        console.log("Rendered Construc",this.props)
        this.state = {
            name:this.props.name,
            timestamp:this.props.timestamp_list,
            mean:this.props.mean,
            mode:this.props.mode,
            redirect:false,
            deviceID:this.props.deviceID,
            action:"dragToZoom"
        };
        console.log(this.state)
        this.stat="cleaned"
        this.handleSubmit =this.handleSubmit.bind(this)
        this.meanEvent=this.meanEvent.bind(this);
        this.modeEvent=this.modeEvent.bind(this);
        this.cleanedEvent=this.cleanedEvent.bind(this);
        this.settingChanged=this.settingChanged.bind(this);
}    
changeGraph=()=>{
  if(document.getElementById(this.props.deviceID+" select"))
  {
    axios.post(testUrl.serverUrl+"api/query/graph",{"device_list":[document.getElementById(this.props.deviceID+" select").value]}).then(res=>{
      console.log(res.data)
      console.log(res.data[0].mode)
      this.setState({
        deviceID:document.getElementById(this.props.deviceID+" select").value,
        outlier:[...res.data[0].mode.filter(d=>{return(d!==null)})].reverse(),
        redirect:true,
        timestamp:[...res.data[0].timestamp.filter((d,index)=>{return(res.data[0].mode[index]!==null)})].reverse()
      })
    }).catch(err=>{
      this.setState({
        deviceID:document.getElementById(this.props.deviceID+" select").value,
        outlier:[],
        redirect:true,
        timestamp:[]
      })
    })
  }
}
   
handleSubmit = e => {
  e.preventDefault();
  let startDate = e.target.from.value;
  let endDate = e.target.to.value;
  let dataType = e.target.select.value;
  let rs="special" // range/special
  let abc=""
  console.log(dataType, this.stat)
  if(dataType==="Default Data"){
    rs="range"
    abc=""
  }
  else if(dataType==="RLD Daily Average")
    abc="/DailyAverage"
  else if(dataType==="RLD Hourly Data")
    abc="/HourlyData"
  else if(dataType==="RLD Monthly Maximum and Minimum")
    abc="/Monthly"

  if(this.stat==="cleaned"){
      axios.get(testUrl.serverUrl+"api/query/device/"+rs+"/"+this.props.deviceID+"/"+startDate+"/"+endDate+abc,{ headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
        console.log(res)
        this.setState({
          redirect:true,
          timestamp:[...res.data.timestamp].reverse(),
          outlier:res.data.mode?[...res.data.mode].reverse():[]
        })
      }).catch(error=>{
        console.log(error.toString())
        if(error.toString()==="Error: Network Error"){
            this.setState({b:"We may not have data in the date range you specified. Please specify a different range"})
          }
      })
  }
  else
  {
    console.log(testUrl.serverUrl+"api/query/device/raw/"+rs+"/"+this.props.deviceID+"/"+this.stat+"/"+startDate+"/"+endDate+abc)
    axios.get(testUrl.serverUrl+"api/query/device/raw/"+rs+"/"+this.props.deviceID+"/"+this.stat+"/"+startDate+"/"+endDate+abc,{ headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
      console.log(res)
      this.setState({
        redirect:true,
        timestamp:res.data.timestamp,
        outlier:(this.stat==="RSSI"||this.stat==="SoC")?(res.data.rawValue):(this.stat==="mean"?res.data.mean:res.data.mode)
      })
    }).catch(error=>{
      if(error.toString()==="Error: Network Error"){
          this.setState({b:"We may not have data in the date range you specified. Please specify a different range"})
        }
    })
  }
}

    componentDidMount()
    {
      if(document.getElementById("cleanedB2")){
        document.getElementById("cleanedB2").style.backgroundColor="#4f4f4f"
        document.getElementById("cleanedB2").style.color="white"
      }
    }
    componentWillReceiveProps(){
      this.setState({redirect:false})
    }

    meanEvent = param => e  => {
      var i;
      for(i=0;i<5;i++){
        document.getElementsByClassName("b2")[i].style.backgroundColor="rgb(224,224,224)"
        document.getElementsByClassName("b2")[i].style.color="#333"
      }
      document.getElementById("meanB2").style.backgroundColor="#4f4f4f"
      document.getElementById("meanB2").style.color="white"
      this.stat="mean"
      this.settingChanged()
    }
    modeEvent = param => e  => {
      var i;
      for(i=0;i<5;i++){
        document.getElementsByClassName("b2")[i].style.backgroundColor="rgb(224,224,224)"
        document.getElementsByClassName("b2")[i].style.color="#333"
      }
      document.getElementById("modeB2").style.backgroundColor="#4f4f4f"
      document.getElementById("modeB2").style.color="white"  
      console.log("modeing")
      e.preventDefault();
      this.stat="mode"
      this.settingChanged()
    }
    socEvent = param => e => {
      var i;
      for(i=0;i<5;i++){
        document.getElementsByClassName("b2")[i].style.backgroundColor="rgb(224,224,224)"
        document.getElementsByClassName("b2")[i].style.color="#333"
      }
      document.getElementById("socB2").style.backgroundColor="#4f4f4f"
      document.getElementById("socB2").style.color="white"
      this.stat="SoC"
      this.settingChanged()
    }
    rssiEvent = param => e => {
      var i;
      for(i=0;i<5;i++){
        document.getElementsByClassName("b2")[i].style.backgroundColor="rgb(224,224,224)"
        document.getElementsByClassName("b2")[i].style.color="#333"
      }
      document.getElementById("rssiB2").style.backgroundColor="#4f4f4f"
      document.getElementById("rssiB2").style.color="white"
      this.stat="RSSI"
      this.settingChanged()
    }
      cleanedEvent = param => e =>{
        var i;
        for(i=0;i<5;i++){
          document.getElementsByClassName("b2")[i].style.backgroundColor="rgb(224,224,224)"
          document.getElementsByClassName("b2")[i].style.color="#333"
        }
        document.getElementById("cleanedB2").style.backgroundColor="#4f4f4f"
        document.getElementById("cleanedB2").style.color="white"
        this.stat="cleaned"
        this.settingChanged()
      }
      UNSAFE_componentWillReceiveProps(newProps) 
      {
        var i,dummy
        for(i=0;document.getElementsByClassName("b2")[i]&&i<5;i++){
          document.getElementsByClassName("b2")[i].style.backgroundColor="rgb(224,224,224)"
          document.getElementsByClassName("b2")[i].style.color="#333"
        }
        document.getElementById("cleanedB2")?document.getElementById("cleanedB2").style.backgroundColor="#4f4f4f":dummy=1
        document.getElementById("cleanedB2")?document.getElementById("cleanedB2").style.color="white":dummy=1
        this.stat="cleaned"
      }
      settingChanged = () => {
        if(this.stat==="cleaned")
        {
          axios.get(testUrl.serverUrl+"api/query/device/"+this.props.deviceID,{ headers:{ "x-access-token":this.props.auth.token}}).then((res)=>{
            // console.log(res)  
            this.setState({
                redirect:true,
                timestamp:res.data.timestamp,
                outlier:res.data.mode
            })
          }).catch(error=>{
            this.setState({
              redirect: true,
              timestamp: [],
              outlier: []
            })
          })

        }
        else
        {
          axios.get(testUrl.serverUrl+"api/query/device/raw/"+this.props.deviceID+"/"+this.stat,{ headers:{ "x-access-token":this.props.auth.token}}).then((res)=>{
            // console.log(res)  
            this.setState({
                redirect:true,
                timestamp:res.data.timestamp,
                outlier:(this.stat==="RSSI"||this.stat==="SoC")?(res.data.rawValue):(this.stat==="mean"?res.data.mean:res.data.mode)
            })
          }).catch(error=>{
            console.log(error.toString())
            if(error.toString()==="Error: Network Error"){
                this.setState({b:"We may not have data in the date range you specified. Please specify a different range"})
              }
          })
        }
      }
    
      render(){
        // console.log(this.state)
        let data1=null;
        let dl
        if(this.props.dontShowOptions)
        dl=this.props.deviceList.map(device=>{return(<option selected={device===this.props.deviceID}>{device}</option>)})
        let xvals=this.state.redirect?(this.state.timestamp?[...this.state.timestamp].reverse():[]):this.props.timestamp_list.filter((d,index)=>{return this.props.mean[index]!==null})
        let yvals= this.state.redirect?(this.state.outlier?[...this.state.outlier].reverse():[]):this.props.mean.filter(d=>{return d!==null})
        let vals=[]
        for(var i=0;i<xvals.length;i++)
          vals.push([new Date(Date.parse(xvals[i])),yvals[i]])
        return (
            <div>
             { (this.props.dontShowOptions)?<div>
               Select Device:<select id={this.props.deviceID+" select"} onChange={()=>{this.changeGraph()}}>{dl}</select>
             </div>:
              <div className="numericB">
                <Button id="cleanedB2"className="b2" onClick={this.cleanedEvent()}>Cleaned</Button>
                <Button id="meanB2" className="b2" onClick={this.meanEvent()}>Mean</Button>
                <Button id="modeB2" className="b2" onClick={this.modeEvent()}>Median</Button>
                <Button id="socB2" className="b2" onClick={this.socEvent()}>SoC</Button>
                <Button id="rssiB2" className="b2" onClick={this.rssiEvent()}>RSSI</Button>
              </div>}
              <div className='view2'>
                <div className="line">
                <Chart
                  height={(this.props.dontShowOptions)?'320px':'calc(100vh - 250px)'}
                  fontSize={'14px'}
                  chartType="AreaChart"
                  loader={<div>Loading Chart</div>}
                  data={vals.length!==0?[
                    ['x', this.stat],
                    ...vals
                  ]:[['x','y'],[0,0]]}
                  options={{
                    hAxis: {
                      title: 'Time',
                      textStyle:{fontSize:10}
                    },
                    colors:["#4bc0c0"],
                    vAxis: {
                      title: 'Value',
                    },
                    chartArea:{backgroundColor:{stroke:"rgba(75,192,192,1)"}},
                    curveType:'function',
                    explorer: { axes:'horizontal', zoomDelta:'0.9', keepInBounds: true}
                  }}
                  rootProps={{ 'data-testid': '1' }}
                />
                </div>
              </div>
              {this.props.dontShowOptions?"":
              <div className="view">
          <form onSubmit={this.handleSubmit}>
            <br />
            <h3>View or Download Data</h3>
            <p>From :</p><input id="calendar" type="date" name="from" required />
            <p>To :</p> <input id="calendar" type="date" name="to" required />
            <select className="select" name="select" required>
              <option value="">Select Intervals</option>
              <option name="cleaned" value="Default Data" >Default Data</option>
              {/* <option name="rld" value="River Level Data (RLD)">River Level Data (RLD)</option> */}
              <option name="hourly" value="RLD Hourly Data" >RLD Hourly Data</option>
              <option name="average" value="RLD Daily Average" >RLD Daily Average</option>
              {/* <option name="five" value="RLD Daily at 8:00 13:00 18:00">RLD Daily at 8:00 13:00 18:00</option> */}
              <option name="maxmin" value="RLD Monthly Maximum and Minimum">RLD Monthly Maximum and Minimum</option>
            </select>
            <div>
              <button name="View" value="View" id="lol">View</button>
              {this.state.b}
            </div>
          </form>
        </div>}
            </div>
        ) 
    }
}
