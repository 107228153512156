import React, { useState } from 'react';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import * as parkData from './distros.json'
import sampI from './test.svg'
import {Icon} from "leaflet"
import './Map.css'
import {Redirect} from 'react-router-dom'
import axios from "axios"
import testUrl from "../../config"

const skater = new Icon({
    iconUrl:"https://i.ibb.co/Q8fzR11/Group-929-3.png",
    iconSize:[70,70],
})

export default function Map(props) {
//   var Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
// 	attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
// });
    const [activePark,setActivePark] = React.useState(null)
    const [data,setData]=useState([])
    var i;
    for(i=0; i<document.getElementsByClassName("leaflet-popup-content").length;i++){
      document.getElementsByClassName("leaflet-popup-content")[i].style.width="90%"
    }
    if(!props.auth.isAuthenticated)
      {
          return (<Redirect to={{pathname: "/"}}/>)
      }
    if(data.length===0){
      axios.get(testUrl.serverUrl+"api/query/map/"+props.auth.user.username,{ headers:{'x-access-token': props.auth.token}}).then((res)=>{
        console.log(res)
        setData(res.data)
      })
    }   
    var i,centroidX=0,centroidY=0;     
    for(i=0;data&&i<data.length;i++)
    {
      if(data[i] && data[i].coordinates.length>=2){
        centroidX+=data[i].coordinates[data[i].coordinates.length-2]?parseFloat(data[i].coordinates[data[i].coordinates.length-2]):0
        centroidY+=data[i].coordinates[data[i].coordinates.length-1]?parseFloat(data[i].coordinates[data[i].coordinates.length-1]):0
        console.log(centroidX,centroidY)
      }
    }
    if(data&&data.length!==0){
      centroidX/=data.length
      centroidY/=data.length
    }
    console.log(centroidX, centroidY)
    return (
      <LeafletMap
        center={[centroidX, centroidY]} //centroid 
        zoom={7.5}
        maxZoom={20}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        {/* <TileLayer
          url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
        /> */}
        <TileLayer url='https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/\{z}/{y}/{x}'/>

        <TileLayer url='https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/\{z}/{y}/{x}' />
      
       {data.map((park,index) => (
         (park&&park.coordinates&&(park.coordinates.length>=2))?
          <Marker key={index} position={[
              park.coordinates[park.coordinates.length-2],          
              park.coordinates[park.coordinates.length-1],
              ]}
              onClick={()=>{
              setActivePark(park)
              }}
              icon={skater}
          />:<div/>
          ))}
          {activePark && activePark.coordinates && activePark.coordinates.length>=2 && (
              <Popup
                position={[
                  activePark.coordinates[activePark.coordinates.length-2],          
                  activePark.coordinates[activePark.coordinates.length-1],
                ]}
                onClose={()=>{
                  setActivePark(null);
                }}
              >
                <div className="popUpDiv">
                  <h4 className="puHeading">Device's Details</h4>
                    <hr className="popUpHr"></hr>
                    <div className="puRow">
                      <div className="puLeftDiv">
                          <p>Name:</p>
                          <p>Id:</p>
                          <p>Co-ordinates:</p>
                          <p>Location:</p>
                          <p>River name:</p>
                      </div>
                      <div className="puRightDiv">
                        <p>{props.auth.user.username}</p>
                        <p>{activePark.deviceID}</p>
                        <p>{activePark.coordinates?"("+activePark.coordinates[activePark.coordinates.length-2]+","+activePark.coordinates[activePark.coordinates.length-1]+")":<p>nbsp;</p>}</p>
                        {activePark.location?<p>{activePark.location}</p>:<p>&nbsp;</p>}
                        {activePark.river?<p>{activePark.river}</p>:<p>&nbsp;</p>}
                      </div>
                    </div>
                    <h4 className="puHeading">Health Status</h4>
                    <hr className="popUpHr"></hr>
                  <div className="puRow">
                      <table>
                          <tr className="puTr">
                            <th>SoC</th>
                            <th>RSSI</th>
                            <th>Sensor last active</th>
                          </tr>
                          <tr>
                            <td>{activePark.SoC}</td>
                            <td>{activePark.RSSI}</td>
                            <td>{activePark.timestamp}</td>
                          </tr>
                        </table>
                      </div>
                  </div>
              </Popup>)}
        </LeafletMap>
    );  
}