import React, { Component } from 'react';
import axios from 'axios'
import {Button} from '@material-ui/core'

export default class DeviceIssue extends Component {
constructor(props) {
           super(props);
           this.state = {comment:null,comment_1:null,};
           this.recordComment = this.recordComment.bind(this)
           this.handleChange = this.handleChange.bind(this)
      }
      recordComment=(e)=>{
          console.log(this.state.comment)
          this.setState({
              comment_1:this.state.comment,
          },()=>{
          const data={
              deviceID:this.props.data.deviceID,
              comment:this.state.comment_1,
              title:this.props.data.Title,
          }
          axios.post("http://0.0.0.0:5000/postAdminComment",data).then(res=>{
          console.log("Comment Added")
          })
          })
}
      handleChange(e){
          this.setState({
              comment:e.target.value
          })
      }
  
      render() {
          console.log(this.props.data)
          let someList;
          if(this.props.data){
          someList=(
              <h4>
                  Hello {this.props.data.deviceID}<br></br>
                  {this.state.comment_1}<br></br>
                  <input type="text" name="issue" placeholder="Comment" onChange={ this.handleChange }/><br></br>
                  <Button variant="outlined" color="primary" onClick={this.recordComment}>Comment</Button>
                   </h4>        )
           }
           return (
               <div>
                   {someList}
               </div>
           );
       }

}
