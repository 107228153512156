import React, { Component } from 'react'
import Navbar2 from '../Navbar/Navbar2'
import { Button } from "react-bootstrap"
import axios from "axios"
import './Message.css'
import  { Redirect,Link } from 'react-router-dom'
import testUrl from "../../config"
import S3FileUpload from 'react-s3'
import ImageModal from './ImageModal.js'
// import {MyContext} from '../../context/DashboardContext.js'

const config = {
    bucketName: 'rishabh-test-bucket103',
    dirName: 'CWC',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZ5IBD6LDNIW6WDG7',
    secretAccessKey: '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',
  }

export default class Message extends Component {
    constructor(props) {
        super(props)
        console.log(props)
        this.state={
            loadFile:"",
            class:"",
            search:'',
            messages:[],
            deviceID:props.location.aboutProps?props.location.aboutProps.deviceID:"",
            issueID:props.location.aboutProps?props.location.aboutProps.issueID:"",
            data1:"",//list of issues
            imageUrl:props.location.aboutProps?props.location.aboutProps.imageUrl:""
        }
        this.submitMessage=this.submitMessage.bind(this)
        this.getMessages=this.getMessages.bind(this)
        this.issueHistory=this.issueHistory.bind(this)
        this.fileSelectedhandler=this.fileSelectedhandler.bind(this)
        this.count=0
    }
    async componentDidMount(){
        await this.issueHistory()
    }
    fileSelectedhandler=event=>{
        if(event)
            S3FileUpload
            .uploadFile(event.target.files[0], config)
            .then(data => this.setState({loadFile:data['location']}))
            .catch(err => console.error(err))
      }
    issueHistory=()=>{
        if(this.props.auth.user)
        { 
            axios.get(testUrl.serverUrl+"api/issues",{ headers:{ "x-access-token":this.props.auth.token, "username":this.props.auth.user.username}}).then(res=>{
                this.setState({
                    data1:res.data
                })
            }).then(()=>{
                if(!this.state.deviceID){
                    axios.get(testUrl.serverUrl+"api/query/user/"+this.props.auth.user.username,{ headers:{ "x-access-token":this.props.auth.token}}).then((res)=>{
                        this.setState({
                            deviceID:res.data.assigned_devices[0],
                            issueID:1
                        })
                    })
                    let dummy
                    document.getElementById("0 issue")?document.getElementById("0 issue").style.color="white":dummy=1
                    document.getElementById("0 issue")?document.getElementById("0 issue").style.backgroundColor="grey":dummy=1
                }
                else{
                    console.log(this.props.location.aboutProps.messageIndex)
                    let dummy
                    document.getElementById(this.props.location.aboutProps.messageIndex+" issue")?document.getElementById(this.props.location.aboutProps.messageIndex+" issue").style.color="white":dummy=1
                    document.getElementById(this.props.location.aboutProps.messageIndex+" issue")?document.getElementById(this.props.location.aboutProps.messageIndex+" issue").style.backgroundColor="grey":dummy=1
                }
                this.getMessages()
            }).catch(error => console.log(error));
        }
    }
    getMessages=()=>{
        axios.get(testUrl.serverUrl+"api/issues/client/"+this.state.deviceID+"/"+this.state.issueID, { headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
            console.log(res.data)
            this.setState({
                messages:res.data
            })
        }).then(res=>{
            if(this.state.messages.length===0 && this.count===0){
                this.count=1
                // axios.post(testUrl.serverUrl+"api/"+"issues/client/"+this.state.deviceID+"/"+this.state.issueID,{"comment":"","imageUrl":this.state.imageUrl}, { headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
                //     console.log(res)
                // }).then(res=>this.getMessages()).catch(error => console.log(error));
            }
        }).catch(error=>console.log(error))
    }
    getMessagesOf(index){
        let dummy=0, count
        for(count=0;count<this.state.data1.length;count++){
            document.getElementById(count+" issue")?document.getElementById(count+" issue").style.color="grey":dummy=1
            document.getElementById(count+" issue")?document.getElementById(count+" issue").style.backgroundColor="rgb(241,241,241)":dummy=1
        }
        document.getElementById(index+" issue")?document.getElementById(index+" issue").style.color="white":dummy=1
        document.getElementById(index+" issue")?document.getElementById(index+" issue").style.backgroundColor="grey":dummy=1
        console.log(this.state.data1)
        axios.get(testUrl.serverUrl+"api/issues/client/"+this.state.data1[index].Device+"/"+this.state.data1[index].ID, { headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
            console.log(res.data)
            this.setState({
                messages:res.data,
                deviceID:this.state.data1[index].Device,
                issueID:this.state.data1[index].ID,
                imageUrl:this.state.data1[index].imageUrl
            })
        })
    }
    submitMessage=e=>{
        e.preventDefault()
        let mb=e.target.messageBody.value?e.target.messageBody.value:""
        if(mb.length!==0 || this.state.loadFile)
        {
            axios.post(testUrl.serverUrl+"api/"+(this.props.auth.user.attributes.profile==="Client"?"issues/client":"admin/issues")+"/"+this.state.deviceID+"/"+this.state.issueID,{"comment":mb,"imageUrl":this.state.loadFile}, { headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
                console.log(res)
                this.getMessages()
                this.setState({loadFile:""})
            }).catch(error => console.log(error));
            document.getElementById("messageForm").reset()
        }
    }
    typing= e =>{
        this.setState({search:e.target.value})
        // console.log(this.state.search)
      }
    render() {
        if(!this.props.auth.isAuthenticated)
            return (<Redirect to={{pathname: "/"}}/>)
        console.log(this.state)
        
        let a=(this.state.messages.map((message)=>{
            return(<div>
                        <div className={this.props.auth.user.attributes.profile==="Client"?(message.issuer==="client"?"qwerty":"asdfgh"):(message.issuer==="client"?"asdfgh":"qwerty")}>
                            {message.image?<ImageModal src={message.image}/>:""}
                            {message.comment!==""?<div>{message.comment}</div>:""}
                            {(message.issuer==="client") === (this.props.auth.user.attributes.profile==="Client")?<div>
                                <div className="arrow-left"></div>
                                <div className="arrow-left-inner"></div>
                            </div>:<div>
                                <div className="arrow-right"></div>
                                <div className="arrow-right-inner"></div>
                            </div>}
                        </div>
                    </div>
                )
            })
        )
        let b //sidebar issues
        if(this.state.data1){
            console.log(this.state.data1)
            if(!this.state.deviceID)
                this.getMessagesOf(0)
            b = (this.state.data1.map((issue, index)=>{
                let boundItemClick = this.getMessagesOf.bind(this, index)
            if(this.state.search!==""&&(issue.Device.toLowerCase().indexOf(this.state.search)===-1&&issue.Title.toLowerCase().indexOf(this.state.search)===-1))
                return null
            return(
                <div className="messageIssueRow" key={index}>
                    <div id={index + " issue"} onClick={boundItemClick} ><p>{issue.Device}&emsp;&emsp;{issue.Title}</p></div>
                </div>
            )
            })
        )
        }
        return (
            <div className="bigDivMessage">
                <Navbar2 auth={this.props.auth}/>
                <div className="userSelect">
                    {/* <div className="buttonDiv">
                       <Button className="userTypeButton">Operations Admin</Button>
                        <Button className="userTypeButton">Software Admin</Button>
                        <Button className="userTypeButton">Client</Button>
                    </div> */}
                    <div id="searchBgDiv">
                        <input id="messageUserInput" type="text" placeholder="Search Issues" name="search" onChange={this.typing} />
                    </div>
                    <div className="userSelectTable">
                        {b}
                    </div>
                </div>
                <div class="Messages">
                    {a}
                </div>
                <div class="messageOptions">
                    <form id="messageForm" onSubmit={this.submitMessage} style={{display:"flex"}}>
                        <textarea rows="4" id="messageText" name="messageBody" placeholder="Type a message"></textarea>
                        <div id="messageButtonBlock">
                        {/* <label for="fileChooser" class="btn">Select Images</label> */}
                            <input type="file" onChange={this.fileSelectedhandler} id="fileChooser" 
                            // style={{"visibility":"hidden"}}
                            ></input>
                            <button id="messageSendButton">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
