import React, { Component } from 'react'
import axios from 'axios'

export default class AddDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption:"",
        }
        this.handleSubmitnewDevice = this.handleSubmitnewDevice.bind(this)

    }
    handleOptionChange = (e) => {
        this.setState({
          selectedOption: e.target.value
        })
      }
    handleSubmitnewDevice = (e) =>{
        e.preventDefault();
        const data = new FormData(e.target);
        let deviceData = {
          deviceID: data.get("deviceID"),
          location: data.get("location"),
          landmark: data.get("landmark"),
          deviceType: this.state.selectedOption
        }
    
        axios.post('http://0.0.0.0:5000/adminDeviceinfo', deviceData).then(response => {
          // this.setState({returnStatus:response.text})
          console.log(response.data.text);
    
        })
    
      }
    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmitnewDevice}>
                    <label htmlFor="deviceID">Id: </label>
                    <input type="text" name="deviceID" />
                    <br />
                    <br />
                    <label htmlFor="location">Location: </label>
                    <input type="text" name="location" />
                    <br />
                    <br />
                    <label htmlFor="landmark">Landmark: </label>
                    <input type="text" name="landmark" />
                    <br />
                    <br />
                    <h3>Type Of Device</h3>
                    <fieldset>
                        <input type="radio" name="" id="1" value="Water Level" checked={this.state.selectedOption === "Water Level"}
                            onChange={this.handleOptionChange} />
                        Water Level
              <br />
                        <input type="radio" name="" id="2" value="Flow Meter" checked={this.state.selectedOption === "Flow Meter"}
                            onChange={this.handleOptionChange} />
                        Flow Meter<br></br>
                    </fieldset>
                    <br />
                    <input type="submit" value="Add Device" />
                </form>
            </div>
        )
    }
}
