import React, { Component } from "react"
import "./Login.css"
import logo from "./images/logo.png"
// import image from "../../images/image.png"
// import { Redirect } from "react-router"
// import axios from "axios"
import {
  // BrowserRouter as Router,
  // Route,
  Redirect,
  // Switch,
  // Link
} from "react-router-dom";
import Amplify ,{Auth} from 'aws-amplify'


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      a:"",
      value: null,
      timestamp:null,
      outlier:null,
    };
    // console.log(this.props)
  }

  handleSubmit= async e =>{
          e.preventDefault();
          let username = e.target.username.value
          let password = e.target.password.value
        try {
            const user = await Auth.signIn(username,password)
            const currentUser = await Auth.currentUserInfo();
            console.log(currentUser)
            this.props.auth.setAuthStatus(true)
            this.props.auth.setUser(currentUser)
            // console.log(this.props)
            // this.setState({"redirect":true})
            
          } catch (exception) {
            console.log(exception)
            if(exception.code=="UserNotFoundException"){
                this.setState({a:"User does not exist"})
            }
            else if(exception.code=="NotAuthorizedException"){
                this.setState({a:"Incorrect password!"})
            }                 
          }
        }
        
        render() {
          let a = null
          if (this.props.auth.isAuthenticated){
          // console.log(this.props.auth.user)
            return (
              <Redirect
              to={{
                // this.props.history.push("/welcome")
            pathname: "/welcome",
            state: { devices: this.state.value,timestamp_list:this.state.timestamp,mode_outlier_list:this.state.outlier }
          }}
        />
      );
    }
    return (
     <div>
      <div className="page-wrap">
        <div className="container">
          <div className="content">
            <div className="firstOne">
              <div className="kritsnam">
                {/* <h1 className="font-normal">Kritsnam Technologies</h1> */}
              </div>
            </div>
            <div className="second">
              <div className="brand-holder">
                <img src={logo} alt="KT" className="lazy-load" />
              </div>
              <div className="forms-container">
                <p className="welcomeHeading">Welcome!</p>
                <div>
                  <div>
                    <div id="login-pane" role="tabpanel">
                      <div className="login-body">
                        <form className="new-user" id="new-user" aria-live="assertive" acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
                          <div className="form-group">          
                            <input className="form-control top" autoFocus="autofocus" autoCapitalize="off" autoCorrect="off" required="required" title="this field is required" type="text" name="username" id="user_login" placeholder="Username"
                            ></input>
                            <p className="gl-field-error hidden">
                              This field is required.
                            </p>
                          </div>
                          <div className="form-group">
                            <input className="form-control pwdControl" required="required" title="this field id required" data-qa-selector="password_field" type="password" name="password" placeholder="Password" id="user_password"/>
                            <br/>
                            <p className="gl-field-error hidden">This field is required.</p>
                          </div>
                          {/* <div className="remember-me">
                            <label for="user_remember_me">
                              <input
                                name="user[remember_me]"
                                type="hidden"
                                value="0"
                              />
                              <input
                                className="remember-me-checkbox"
                                type="checkbox"
                                value="1"
                                name="user[remember_me]"
                                id="user[remember_me]"
                              />
                              <span>Remember me</span>
                            </label>
                          </div> */}
                          <div className="submit-container">
                            <input
                              type="submit"
                              name="commit"
                              value="Login"
                              className="btn-login"
                              data-qa-selector="sign_in_button"
                              data-disable-with="Login"
                            />
                            {/* <Link to="/admin">
                              <button>Log in to admin console</button>
                            </Link> */}
                          </div>
                        </form>
                        {this.state.a}
                        <hr className="copyrightLine"></hr>
                            <div className="copyrightText">
       All rights reserved<br></br>
       Copyright &copy;{new Date().getFullYear()} Kritsnam Technologies. 
       </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        </div>
      </div>
      
       </div>
    );
  }
}

export default Login
