import React, { Component } from "react";
import "./DeviceDetails.css";
// import ReactTable from 'react-table';
// import 'react-table/react-table.css'
// import Sidebar from "../Sidebar/Sidebar";
// import Navigator from "../Navigator/Navigator";
import battery from "../../images/icons/battery/3.png";
import signal from "../../images/icons/signal/6.png";
import sensor from "../../images/icons/sensor/1.png";
import imu from "../../images/icons/imu/2.png";
import data from "../../images/icons/data transmission/1.png";
import clock from "../../images//icons/clock/1.png";
import thermo from "../../images/icons/temperature/1.png";
import battery100 from "./battery100.png"
import battery75 from "./battery75.png"
import battery50 from "./battery50.png"
import battery25 from "./battery25.png"
import battery0 from "./battery0.png"
import signal0 from "./0.png"
import signal10 from "./10.png"
import signal20 from "./20.png"
import signal40 from "./40.png"
import signal60 from "./60.png"
import signal80 from "./80.png"
import signal100 from "./100.png"
import axios from 'axios'
import testUrl from "../../config"




class DeviceDetails extends Component 
{
  constructor(props){
    super(props);
    console.log("Device Details props ", this.props)    
    this.state={
      deviceID:this.props.deviceID,
      location:this.props.location?this.props.location:"",
      devImage:"",
      redirect:false,
      river:"",
      timestamp:"",
    }
    this.getNewData=this.getNewData.bind(this);
  }
  componentDidMount(){
    axios.get(testUrl.serverUrl+"api/query/device/attributes/"+this.props.deviceID, { headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
      console.log(res)
      this.setState({
        timestamp:res.data.timestamp,
        RSSI:res.data.RSSI,
        SoC:res.data.SoC,
        VCell:res.data.VCELL,
        deviceID:res.data.deviceID
      })
    })
    axios.get(testUrl.serverUrl+"api/query/"+this.props.deviceID, { headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
      console.log(res)
      this.setState({
        location:res.data.location,
        river:res.data.river,
        devImage:res.data.deviceImage
      })
    })
  }

  componentWillReceiveProps(){
    this.setState({
      redirect:true
    })
  }
  
  getNewData(){
    axios.get(testUrl.serverUrl+"api/query/device/attributes/"+this.props.deviceID,{ headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
      // console.log(res)
      this.setState({
        timestamp:res.data.timestamp,
        RSSI:res.data.RSSI,
        SoC:res.data.SoC,
        VCell:res.data.VCELL,
        redirect:false
      })
    }).catch(error=>console.log(error))
    axios.get(testUrl.serverUrl+"api/query/"+this.props.deviceID,{ headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
      // console.log(res)
      this.setState({
        devImage:res.data.deviceImage,
        location:res.data.location,
        river:res.data.river,
        redirect:false
      })
    }).catch(error=>console.log(error))
  }
  
  render()
  {
    if(this.state.redirect){
      this.getNewData()
    }
    
    let batteryLevel,y=this.state.SoC;
    if(y>87.5){
      batteryLevel=(<img id="battery" src={battery100} alt="battery" />)
    }
    else if(y>62.5){
      batteryLevel=(<img id="battery" src={battery75} alt="battery" />)
    }
    else if(y>37.5){
      batteryLevel=(<img id="battery" src={battery50} alt="battery" />)
    }
    else if(y>15.5){
      batteryLevel=(<img id="battery" src={battery25} alt="battery" />)
    }
    else if(y>0){
      batteryLevel=(<img id="battery" src={battery0} alt="battery" />)
    }
    let wifiLevel, y1=this.state.SoC;
    if(y1>90){
      wifiLevel=(<img id="signal" src={signal100} alt="signal" />)
    }
    else if(y1>70){
      wifiLevel=(<img id="signal" src={signal80} alt="signal" />)
    }
    else if(y1>50){
      wifiLevel=(<img id="signal" src={signal60} alt="signal" />)
    }
    else if(y1>30){
      wifiLevel=(<img id="signal" src={signal40} alt="signal" />)
    }
    else if(y1>15){
      wifiLevel=(<img id="signal" src={signal20} alt="signal" />)
    }
    else if(y1>5){
      wifiLevel=(<img id="signal" src={signal10} alt="signal" />)
    }
     else{
       wifiLevel=(<img id="signal" src={signal0} alt="signal" />)
     }                         

    return (
      <div>
        {/* <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={2}
          pageSizeOptions={[2, 4, 6]}
        />
        <Navbar />
        <Sidebar />
        <Navigator /> */}
        <div className="device_details">
        <div className="leftDiv">
            <div className="leftleftDiv">
              {/* Operation admin's name:<br></br>  */}
              Username:<br></br>
              Device ID: <br></br>
              River name:<br></br>
              Location: <br></br>
              {/* Image: */}
            </div>
            <div className="leftrightDiv">
              {this.props.auth.user.username}<br></br>
              {this.props.deviceID}<br></br>
              {this.state.river}<br></br>
              {this.state.location} <br></br>
              {/* <img src={this.state.devImage} className="devImage"/> */}
            </div>
               
        </div>
          <div className="telemetry">
              <h3 id="dp">Device Parameters</h3>
              <table id="table1" cellPadding="12">
                  <tr>
                  <td id="attribute">Sensor last active</td>
                    <td><div  id="ts">
                    {this.state.timestamp.split(" ")[1]}
                    </div></td>
                    <td id="value">{this.state.timestamp.split(" ")[0]}</td>
               </tr>

              <tr>
                <td id="attribute">SoC (Battery)</td>
                <td id="icon">
                 {batteryLevel}
                </td>
                <td id="value">{this.state.SoC}</td>
              </tr>
              <tr>
                <td id="attribute">RSSI (Signal Strength)</td>
                <td id="icon">
                  {wifiLevel}
                </td>
                <td id="value">{this.state.RSSI}</td>
              </tr>
              {/* <tr>
                <td id="attribute">Sensor in-range</td>
                <td id="icon">
                  <img src={sensor} alt="sensor" />
                </td>
                <td id="value">(70~80)</td>
              </tr>
              <tr>
                <td id="attribute">IMU Status</td>
                <td id="icon">
                  <img id="imu" src={imu} alt="imu" />
                </td>
                <td id="value">270 BMI</td>
              </tr>
              <tr>
                <td id="attribute">Valid data transmission</td>
                <td id="icon">
                  <img id="data" src={data} alt="transmission" />
                </td>
                <td id="value">(70~80)</td>
              </tr>
              <tr>
                <td id="attribute">Sensor Last Active</td>
                <td id="icon">
                  <img id="clock" src={clock} alt="clock" />
                </td>
                <td id="value">18 Dec 2019</td>
              </tr>
              <tr>
                <td id="attribute">On-Board Temperature</td>
                <td id="icon">
                  <img id="thermo" src={thermo} alt="temperature" />
                </td>
                <td id="value">42°C</td>
            </tr> */}
                        </table>
          </div>
        </div>
          <img src={this.state.devImage} className="devImg"/>
      </div>
    );
  }
}

export default DeviceDetails;
