 import React, { Component } from 'react'
import {MyDashboardContext} from '../Login/UserInfo'
// import { MyContext } from '../../context/DashboardContext';
import Logout from '../Login/Logout'
import Navbar2 from '../Navbar/Navbar2'
import Sidebar from '../Sidebar/Sidebar'
import Navigator from '../Navigator/Navigator'
import AdminLanding from '../AdminPanel/AdminLanding/AdminLanding'
export default class Landing extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div>
               <MyDashboardContext.Consumer>
                       {(context)=>
                       {
                        return (<React.Fragment>
                           <div>
                                <Sidebar context = {context}></Sidebar>
                                <Navbar2 data={context}></Navbar2>
                           </div>
                           </React.Fragment>)}
                       }
               </MyDashboardContext.Consumer>
            </div>
        )
    }
}
