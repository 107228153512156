import React, { Component } from "react";
import "./Navbar2.css";
import logo from "./cwclogo.jpg";
import { AiFillHome } from "react-icons/ai";
import { FaRegEnvelope,FaRegBell,FaRegUserCircle} from "react-icons/fa";
import Profile from '../Profile/Profile'
import {FiLogOut} from "react-icons/fi"
import Logout from '../Login/Logout'
import  { Redirect,Link } from 'react-router-dom'
// import {Navbar} from "react-bootstrap"
// import {Button} from '@material-ui/core';
// import { FaSignOutAlt } from "react-icons/fa"
import AdminLanding from '../AdminPanel/AdminLanding/AdminLanding'

class Navbar2 extends Component {

  constructor(props){
    super(props);
    this.state = {
      redirect:false
    }
    // console.log(props)
  }
  render() {
    return (
      <nav className="krits-nav nav-collapse">
        <a href="/"><img src={logo} alt="kritsnam_logo"></img></a>

        <a href="/">
          <p>Central Water Commission</p>
        </a>
        <div className="header">
          <ul>
            {this.props.auth?(this.props.auth.user.attributes.profile==="Client"?"":<li><Link to={{pathname:'/adminLanding',aboutProps:{data:this.props.data}}} rel='noopener noreferrer' >Admin Options</Link></li>)
            :(this.props.data.auth.user.attributes.profile==="Client"?"":<li><Link to={{pathname:'/adminLanding',aboutProps:{data:this.props.data}}} rel='noopener noreferrer' >Admin Options</Link></li>)}
            <li><a href="/">Home</a></li>
            <li>
              <Link to={{pathname:'/issue',aboutProps:{deviceList:this.props.data?this.props.data.LoD:[]}}} rel='noopener noreferrer' >Issues </Link>
            </li>
            <li>           
                <Link to={{pathname:'/message',aboutProps:{data:this.props.data}}} rel='noopener noreferrer'>Message </Link>   
            </li>
            <li>
              <div>         
                   {/* <Link to='/profile'>
                   <FaRegUserCircle />
               </Link>*/}
                  {/*onClick={this.ProfilePage}*/}
                  <Logout auth={this.props.data?this.props.data.auth:this.props.auth} className="logOut"></Logout>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
export default Navbar2