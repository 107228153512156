import React, { Component } from "react";
// import {
//   Charts,
//   ChartContainer,
//   ChartRow,
//   YAxis,
//   LineChart
// } from "react-timeseries-charts";
// import RTChart from "react-rt-chart";

import { Button } from "react-bootstrap";
import "./Calibration.css";

class Calibration extends Component {
    render() {
      return (
        // <ChartContainer timeRange={series1.timerange()} width={800}>
        //   <ChartRow height="200">
        //     <YAxis
        //       id="axis1"
        //       label="AUD"
        //       min={0.5}
        //       max={1.5}
        //       width="60"
        //       type="linear"
        //       format="$,.2f"
        //     />
        //     <Charts>
        //       <LineChart axis="axis1" series={series1} column={["aud"]} />
        //       <LineChart axis="axis2" series={series2} column={["euro"]} />
        //     </Charts>
        //     <YAxis
        //       id="axis2"
        //       label="Euro"
        //       min={0.5}
        //       max={1.5}
        //       width="80"
        //       type="linear"
        //       format="$,.2f"
        //     />
        //   </ChartRow>
        // </ChartContainer>
        <div className="backdrop">
            <div className="calibration">
                <input id="fill" type="date"/>
                <label>
                    <input type="radio" name="time"/>0800 Hrs
                </label>
                <label>
                    <input type="radio" name="time"/>1300 Hrs
                </label>
                <label>
                    <input type="radio" name="time"/>1800 Hrs
                </label>
                <input id="fill" type="text" name="insert" placeholder="Enter the calibrated value..."/>
                <Button>Submit</Button>
            </div>
        </div>
      );
    }
  // componentDidMount() {
  //   setInterval(() => this.forceUpdate(), 1000);
  // }

  // render() {
  //   var data = {
  //     date: new Date(),
  //     Car: Math.floor(Math.random() * 50) + 1 ,
  //     Bus: Math.floor(Math.random() * 50) + 1 
  //   };

  //   return <RTChart fields={["Car", "Bus"]} data={data} />;
  // }
}

export default Calibration;
