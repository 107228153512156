import React, { Component } from 'react';
import axios from 'axios'
import {Button} from 'react-bootstrap'
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import './issueHistory.css'
import Navbar2 from '../Navbar/Navbar2'
import testUrl from "../../config"
import S3FileUpload from 'react-s3'

const config = {
    bucketName: 'rishabh-test-bucket103',
    dirName: 'CWC',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZ5IBD6LDNIW6WDG7',
    secretAccessKey: '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',
  }
export default class IssueHistory extends Component {
    constructor(props) {
        super(props);
        this.state={
            data1:[],
            deviceList:this.props.location.aboutProps?this.props.location.aboutProps.deviceList:[],
            message:null,
            particularData:null,
            loadFile:""
}
    this.baseState=this.state
    this.issueHistory=this.issueHistory.bind(this)
    this.postIssue=this.postIssue.bind(this)
    this.getDeviceList=this.getDeviceList.bind(this)
    this.fileSelectedhandler=this.fileSelectedhandler.bind(this)
    // this.fileUploadhandler=this.fileUploadhandler.bind(this)
    }
    fileSelectedhandler=event=>{
        if(event)
            S3FileUpload
            .uploadFile(event.target.files[0], config)
            .then(data => this.setState({loadFile:data['location']}))
            .catch(err => console.error(err))
      }
    getDeviceList=()=>{
        axios.get(testUrl.serverUrl+"api/query/user/"+this.props.auth.user.username,{ headers:{ "x-access-token":this.props.auth.token}}).then((res)=>{
            this.setState({deviceList:res.data.assigned_devices})
        })
    }

    issueHistory=()=>{
        axios.get(testUrl.serverUrl+"api/issues",{ headers:{ "x-access-token":this.props.auth.token, "username":this.props.auth.user.username}}).then(res=>{
            console.log(res.data)
            this.setState({data1:res.data})
        })
    }
    postIssue=e=>{
        e.preventDefault()
        let issueName=e.target.issueName.value
        let issueDev=e.target.issueDev.value
        let issueDescription = e.target.issueDescription.value
        axios.post(testUrl.serverUrl+"api/issues",{"title":issueName, "device":issueDev, "description":issueDescription, "ImageUrl":this.state.loadFile},{ headers:{ "x-access-token":this.props.auth.token}}).then(res=>{
            console.log(res)
            if(this.state.loadFile)
            {
                axios.post(testUrl.serverUrl+"api/"+(this.props.auth.user.attributes.profile==="Client"?"issues/client/":"admin/issues/")+issueDev+"/"+res.data,{"comment":"","imageUrl":this.state.loadFile}, { headers:{ "x-access-token":this.props.auth.token}}).then(r=>{
                    // console.log(r)
                })
            }
            this.setState({loadFile:""})
            this.issueHistory()
        })
    }

    componentDidMount(){
            this.issueHistory()
    }
    GotoDownloadpage=(number,e)=>{
        this.setState({particularData:number,})
    }

    render() {
        if(!this.props.auth.isAuthenticated)
        {
            return (
                <Redirect to={{pathname: "/" }}/>
            )
        }
        console.log(this.state)
        if(this.state.deviceList.length===0)
        {
            console.log("Condition is true")
            this.getDeviceList()
        }
    console.log(this.state.data1)
    const listItems = this.state.data1.map((number, index)=>{
         let boundItemClick = this.GotoDownloadpage.bind(this, number)
         return (
         <tr>
           <td>
           {number.Title}
           </td>
           <td>{number["Date Created"]}</td>
           <td>{number.Device}</td>
            <td>{number.Status}</td>
            <td className="issueDesc">{number.Description}</td>
            <td><Link to={{pathname:'/message',aboutProps:{deviceID:number.Device, issueID:number.ID, messageIndex:index, imageUrl:number.imageUrl}}} rel='noopener noreferrer'><Button className="messageButton">Track this issue </Button></Link></td>
         </tr>)
         });
         let a=this.state.deviceList.map((device)=>{
            return (<option>{device}</option>)
        })
     //const listItems = this.state.data1.map((d) => <li key={d.deviceID}>{d.deviceID}</li>);

         
        return(
        <div>
            <Navbar2 auth={this.props.auth}/>
            <div id="issueBigDiv">
                <br></br>
                <div className="block1">
                    <h3>{this.props.auth.user?this.props.auth.user.attributes.profile:""} Detail</h3><br></br>
                    <div className="lr">
                        <div className="issueLeftDiv">
                            Name:<br/>
                            Network:<br/>
                            Role:
                        </div>
                        <div className="issueRightDiv">
                        {this.props.auth.user?this.props.auth.user.username:""}<br/>
                        {this.props.auth.user?this.props.auth.user.attributes.zoneinfo:""}<br/>
                        {this.props.auth.user?this.props.auth.user.attributes.profile:""}<br/>
                        
                        </div>
                    </div>
                </div>
                <table className="issueTable">
                    <tr>
                        <th>Name of issues&emsp;</th>
                        <th>Date of issues&emsp;</th>
                        <th>Device ID&emsp;</th>
                        <th>Issue status&emsp;</th>
                        <th className="issueDesc">Description</th>
                        <th>Tracking</th>
                    </tr>
                {   listItems}
                </table>
                <br/>
            </div> 
            <div>
                <form onSubmit={this.postIssue}>
                    <div className="formContainer">
                <h3 style={{"text-align":"center"}}>Create new issue:</h3><br></br>
                        <div className="formRow">
                            <p className="formLabel">Name of issue:</p><input type="text" name="issueName" id="issueInput" required></input>
                        </div>
                        <div className="formRow">
                            <p className="formLabel">Issue Description:</p><textarea name="issueDescription" rows="4" id="issueInput2" required/>
                        </div>
                        <div className="formRow">
                            <p className="formLabel">Device ID:</p><select name="issueDev">{a}</select>
                        </div>
                        <input type="file" onChange={this.fileSelectedhandler} style={{"font-size":"14px","width":"100%"}}></input>
                        <div className="formRow"><p className="formLabel"></p><button className="messageButton">Post issue</button></div>
                    </div>
                </form>
            </div>
        </div>
        )
    }
}
