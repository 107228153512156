import {Modal} from "react-bootstrap"
import {Button} from "react-bootstrap"
import React, { useState, useEffect } from 'react';
import "./DeviceAttributesModal.css"
import axios from "axios"
import testUrl from "../../../config"
import S3FileUpload from 'react-s3';

function DeviceAttributesModal(props) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [loadFile,setLoadFile]=useState("")
    const [a, setA]=useState("")
    const config = {
        bucketName: 'rishabh-test-bucket103',
        dirName: 'CWC',
        region: 'ap-south-1',
        accessKeyId: 'AKIAZ5IBD6LDNIW6WDG7',
        secretAccessKey: '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',
      }
    
    const handleSubmit = (event) => {
        event.preventDefault()
        let sensor=[]
        if(event.target.wl.checked)
            sensor.push(event.target.wl.value)
        if(event.target.fm.checked)
            sensor.push(event.target.fm.value)
        console.log(sensor)
        let body={"device_id":event.target.deviceID.value,"location":event.target.location.value,
        "river":event.target.riverName.value,"sensor":sensor,"measParam":event.target.mp.value,coordinates:[event.target.coord1.value,event.target.coord2.value],
        "measParam":event.target.mp.value,"network":event.target.network.value, "deviceImage":loadFile}
        console.log(body)
        axios.put(testUrl.serverUrl+"api/admin/device/attributes",body,{ headers:{ "x-access-token":props.auth.token}}).then(res=>{
            console.log(res)
            setA("Attributes successfully assigned")
            setTimeout(()=>{setA("")},5000)
            setLoadFile("")
        }).catch(error=>{setA("Failed to set attributes. There may be a connection problem. Please try again later")})
    }
    console.log(props.listOfDeviceNames)
    let devices=props.listOfDeviceNames.map(device=>{
        return (<option>{device}</option>)
    })
    return (
        <>
            <Button className="deviceAttributesButton" onClick={handleShow}>Assign attributes to a device</Button>
            <Modal style={{"opacity":"1", "background-color": "rgba(19,19,19,0.8)", "padding-top":"30vh"}} show={show} onHide={handleClose}>
                <Modal.Title>
                    Assign attributes to device
                </Modal.Title>
                <Modal.Body id="assignAttributesModal">
                    <form onSubmit={handleSubmit}>
                        Select device <select id="deviceID" required>
                            {devices}
                        </select><br/>
                        Type in the location:<input id="location" type="text"/><br/>
                        Type in the river name:<input id="riverName" type="text"/><br/>
                        Sensor type:<br/><input type="checkbox" id="wl" name="wl" value="Level"/><label for="wl">Water Level</label><br/>
                        <input type="checkbox" id="fm" name="fm" value="Flow Meter"/><label for="fm">Flow meter</label><br/>
                        Parameters being measured:<input id="mp" type="text"/><br/>
                        Coords:<input  id="coord1" type="number" step="any"/><input id="coord2" type="number" step="any"/><br/>
                        Select Network:<select id="network"><option value="CWC">CWC</option><option value="Kristnam">Kritsnam</option></select><br/>
                        Device Image:  <input type="file" style={{"font-size":"14px","width":"100%", "padding-left":"0px"}} onChange={event=>{
                                if(event)
                                S3FileUpload
                                .uploadFile(event.target.files[0], config)
                                .then(data => {
                                    setLoadFile(data['location'])
                                    console.log(loadFile)
                                })
                                .catch(err => console.error(err))
                                }}>
                            </input>
                        <button>Submit</button>
                    </form>
                    <br/>
                    <div>{a}</div>
                </Modal.Body>
            </Modal>
        </>
        )
}
export default DeviceAttributesModal