import React, { Component } from "react";
import { Button } from "react-bootstrap"
import './Alert.css'

export default class numeric extends Component {


    constructor(props) {
      super(props);
    }
    render(){
        return(
            <div className="bigDiv">
                <select className="sat"><option>Select alert type</option>
                <option>Level at risk point</option>
                <option></option>
                <option></option>
                </select>
                <input placeholder="Custom value" id="cust"></input>
                <Button id="alertButton">Create Alert</Button>
                <br></br>
                <div className="ca">Current Alerts:</div><br></br>
                <table className="alertTable">
                    <tr className="headers"><th>Type</th><th>Alert Value</th><th>Status</th><th>Edit</th></tr>
                    <tr><td>a</td><td>b</td><td>c</td><td>d</td></tr>
                    <tr><td>a</td><td>b</td><td>c</td><td>d</td></tr>
                </table>
                <br></br>
                <div className="ca">History:</div>
                <table className="alertTable">
                    <tr className="headers"><th>Type</th><th>Alert Value</th><th>Time</th><th>Date</th><th>Edit</th></tr>
                    <tr><td>a</td><td>b</td><td>c</td><td>d</td></tr>
                    <tr><td>a</td><td>b</td><td>c</td><td>d</td></tr>
                    <tr><td>a</td><td>b</td><td>c</td><td>d</td></tr>
                </table>
                
            </div>
        )
    }
}