import {Modal} from "react-bootstrap"
import {Button} from "react-bootstrap"
import React from 'react'
import {useState} from 'react'
import axios from "axios"
import './IssueModal.css'
import  { Redirect,Link } from 'react-router-dom'
import testUrl from "../../config"
import S3FileUpload from 'react-s3';

function IssueModal(props) {
    const [show, setShow] = useState(false)
    const [a, setA]=useState("")
    const [selectedFile,setSelectedFile]=useState("")
    const [loadFile,setLoadFile]=useState("")
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const config = {
      bucketName: 'rishabh-test-bucket103',
      dirName: 'CWC',
      region: 'ap-south-1',
      accessKeyId: 'AKIAZ5IBD6LDNIW6WDG7',
      secretAccessKey: '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',
    }
  return (
      <>
        <Button  id="issue" onClick={handleShow} className="bReport">Report Issues</Button>

        <Modal style={{opacity:1, "background-color": "rgba(19,19,19,0.8)", "z-index":1000, "padding-top":"30vh"}} show={show} onHide={handleClose}>
    <Modal.Title id="modalTitle">Create an issue for {props.deviceID}</Modal.Title>
          <Modal.Body id="modalBody">
            <div className="ModalRow">
              <p className="modalFormLabel">Name of issue:</p><input type="text" name="issueName" id="modalIssueInput" required/><br/>
            </div>
            <div className="ModalRow">
              <div className="modalFormLabel">Issue Description:</div><textarea rows="4" id="modalIssueInput2" required/>
            </div>
            <input type="file" style={{"font-size":"14px","width":"100%", "padding-left":"0px"}} onChange={event=>{
              // event?setSelectedFile(event.target.files[0]):setSelectedFile("")
              if(event)
              S3FileUpload
              .uploadFile(event.target.files[0], config)
              .then(data => {
                setLoadFile(data['location'])
                console.log(loadFile)
              })
              .catch(err => console.error(err))
            }}></input>
            <Button className="issuePostButton" onClick={()=>{
                if(document.getElementById("modalIssueInput").value){
                  if(document.getElementById("modalIssueInput2").value){ 
                    axios.post(testUrl.serverUrl+"api/issues",{"title":document.getElementById("modalIssueInput").value, "device":props.deviceID, "description":document.getElementById("modalIssueInput2").value, "ImageUrl":loadFile},{ headers:{ "x-access-token":props.auth.token}}).then(res=>{
                      if(loadFile){
                        axios.post(testUrl.serverUrl+"api/"+(props.auth.user.attributes.profile==="Client"?"issues/client/":"admin/issues/")+props.deviceID+"/"+res.data,{"comment":"","imageUrl":loadFile}, { headers:{ "x-access-token":props.auth.token}}).then(r=>{
                          console.log(res)
                        })
                      }
                      // console.log(res)
                      setA(<div>Issue successfully posted. To follow the issue, <Link to={{pathname:'/issue', aboutProps:{deviceList:props.deviceList}}} rel='noopener noreferrer'>click here </Link></div>)
                    })
                  }
                  else
                    setA(<div>Please give a valid description to your issue </div>)
                }
                else
                  setA(<div>Please give a valid title to your issue </div>)
            }}>Post issue</Button>
           
            {a}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default IssueModal