import Amplify ,{Auth} from 'aws-amplify'
import React, { Component } from 'react'
import Profile from './components/Profile/Profile'
import IMap from './components/Maps/Map'
import Message from './components/Message/Message'
import UserInfo from './components/Login/UserInfo'
import {MyDashboardContext} from './components/Login/UserInfo'
import c3 from 'c3';
import DashboardContext from './context/DashboardContext'
import Chart from './Chart'
import './App.css';
// import io from 'socket.io-client'
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom'
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import IssueHistory from './components/IssueTracking/IssueHistory';
import Login from './Login'
import AdminLanding from './components/AdminPanel/AdminLanding/AdminLanding'

Amplify.configure({
  region: 'ap-southeast-1',
  userPoolId:  "ap-southeast-1_tJvxELAWd",//
  // userPoolId:'ap-southeast-1_A0bLWGewI',
  userPoolWebClientId: "arjhaaoar7t243906j0mkef6u"//
  // userPoolWebClientId:'50r3bqek7p089ds11ajd1cids3',
})

class App extends Component {

    state = {
        isAuthenticated: false,
        isAuthenticating: true,
        user: null,
        token:null
      }
      setAuthStatus = authenticated=>{
        this.setState({isAuthenticated:authenticated})
      }
      setUser = user=>{
        this.setState({user:user})
      }
      setToken = token=>{
        this.setState({token:token})
      }

      async componentDidMount(){
        try {
          const session = await Auth.currentSession();
          const user = await Auth.currentUserInfo();
          this.setAuthStatus(true);
          this.setToken(session.getAccessToken().getJwtToken())
          this.setUser(user)
        } catch (error) {
          if(error !== "No current user")
            console.log(error);
        }
        this.setState({isAuthenticating:false})
        // console.log(Auth.currentSession())
      }
    render() {
      // console.log(this.props)
        const authProps={
            isAuthenticated:this.state.isAuthenticated,
            user:this.state.user,
            setAuthStatus:this.setAuthStatus,
            setUser:this.setUser,
            setToken:this.setToken,
            token:this.state.token
          }

        return (
           !this.state.isAuthenticating &&
            <React.Fragment> 
                <Router>
                    <Switch>
                    <Route exact path="/" render={(props)=><Login {...props} auth={authProps}/>}/>
                    {/* <Route exact path="/login" render={(props)=><Login {...props} auth={authProps}/>}></Route> */}
                    <Route exact path="/welcome" render={(props)=><UserInfo {...props} auth={authProps}/>}></Route>
                        {/* <Route path="/home" exact component={UserInfo}/> */}
                        <Route path="/profile" exact component={Profile} />
                        <Route path="/issue" exact render={(props)=><IssueHistory {...props} auth={authProps}/>} />
                        <Route path="/map" exact render={(props)=><IMap {...props} auth={authProps}/>} />
                        <Route path="/message" render={(props)=><Message {...props} auth={authProps}/>}/>
                        <Route path="/adminLanding" render={(props)=><AdminLanding {...props} auth={authProps}/>}/>
                    </Switch>
                </Router>
            </React.Fragment>
        )
    }
}

export default App;
