import React, { Component } from "react";
import "./Sidebar.css";
import axios from 'axios'
import { FaSearch } from "react-icons/fa";
import { Button } from "react-bootstrap";
import {MyDashboardContext} from '../Login/UserInfo'
import Navigator from '../Navigator/Navigator'
import {Link} from 'react-router-dom'
import testUrl from "../../config"
export const MySideBarContext = React.createContext();

const a = null;

class Sidebar extends Component {

  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      name:this.props.context.name,
      search:'',
      deviceList:this.props.context.LoD,
      timestamp:this.props.context.timestamp_list,
      mean:this.props.context.mean,
      mode:this.props.context.outlier_list,
      value:this.props.context.deviceID,
      location:this.props.context.locations?this.props.context.locations[0]:"",
      updated:false,
      selected:1
				
    };
    this.total=0
    this.visibility=true
  }
  afterStatefun(){

    // console.log(this.state.mean);
    this.setState({
      updated:true
    })
  }

  componentDidUpdate(){
    // console.log("State has been updated",this.state)
  
  }
seeMap(){
  return (
        <div>
          <Link to='/map'>
            <button type="button" className="btn btn-info">Button</button>
          </Link>
        </div>
  )
}
  GotoDownloadpage(number,count,e){
    var i, dummy;
    // console.log(this.total)
    for(i=1;i<=this.total;i++){
      document.getElementById(""+i)?document.getElementById(""+i).style.backgroundColor="white":dummy=0
      document.getElementById(""+i)?document.getElementById(""+i).style.color="#27ae60":dummy=0
    }
    document.getElementById(""+count).style.backgroundColor="#4f4f4f"
    document.getElementById(""+count).style.color="#6fcf97"

    // console.log(number)
    axios.get(testUrl.serverUrl+"api/query/device/"+number,{ headers:{'x-access-token': this.props.context.auth.token}}).then(res=>{
      console.log(res)
      this.setState({
        timestamp:res.data.timestamp,
        mean:res.data.mode,
        value:number,
        selected:count,
        location:this.props.context.locations[count-1]
      })
    }).catch(error=>{
      console.log(error)
      this.setState({
        timestamp:[],
        mean:[],
        value:number,
        selected:count
      })
    })
  }

  typing= e =>{
    this.setState({search:e.target.value})
  }

  render() {
    const numbers = this.state.deviceList;
    // console.log(this.state.mode);

      const someList = <Navigator name={this.state.name} timestamp_list={this.state.timestamp} location={this.state.location}
      mean={this.state.mean} mode={this.state.mode} deviceID={this.state.value} deviceList={this.state.deviceList} navbar={this.props.context.navbar} auth={this.props.context.auth}></Navigator>
    	
   
    let count=0;
    let listItems = numbers.map((number)=>{
      count++;
      let boundItemClick = this.GotoDownloadpage.bind(this, number, count);
      if(this.state.search!==""&&number.toLowerCase().indexOf(this.state.search)===-1)
        return null
      return (<div id={count} onClick={boundItemClick} style={count===this.state.selected?{"backgroundColor":"#4f4f4f"}:{}}>
        <div > {number} </div><div >{this.props.context.locations[count-1]}</div>
      </div>)

      });
     this.total=count;
    return (
      <React.Fragment>
        <div className="sidebar">
        <div id="main">
          <button class="openbtn" onClick={()=>{
            document.getElementsByClassName("sidebar")[0].style.visibility=this.visibility?"visible":"hidden"
            document.getElementsByClassName("sidebar")[0].style.display="block"
            // document.getElementsByClassName("sidebar")[0].style.opacity="0.7"
            document.getElementsByClassName("first")[0].style.left=this.visibility?"250px":"0px"
            document.getElementsByClassName("first")[0].style.width=this.visibility?"calc(100% - 25 0px)":"100%"
            document.getElementsByClassName("openbtn")[0].innerHTML=this.visibility?"Close Sidebar":"Open Sidebar"
            this.visibility=!this.visibility
        }}>Open Sidebar</button>
        </div>
          <div className="buttonC">
            <a href="#">
              <Button id="device">Device List</Button>
            </a>
              <Link to="/map"><Button id="maps">Map</Button></Link>
            <div id="searchBox">
            <input id="search_text"
              type="text"
              placeholder="Search Devices"
              name="search"
              size="15"
              onChange={this.typing}
            />
            </div>
          </div>
        <div cellPadding="8" cellSpacing="2" className="side_table">    
          {listItems}
        </div>
        <FaSearch class="iconC"/>      
      </div>
      {someList}
      </React.Fragment>
    );
}
}
export default Sidebar;
