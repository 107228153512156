import {Modal} from "react-bootstrap"
import {Button} from "react-bootstrap"
import React, { useState, useEffect } from 'react';
import "./CreateUserModal.css"
import axios from "axios"
import testUrl from "../../../config"

function CreateIssueModal(props) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [a, setA]=useState("")
    const [b, setB]=useState("")

    const handleSubmit = (event) => {
        let uname
        if(event.target)
            uname = event.target.uname.value
        event.preventDefault()
        // console.log(uname)
        if(event.target.pwd.value!==event.target.cpwd.value){
            setA("Password and confirm password do not match")
        }
        if(event.target.pwd.value.length < 6){
            setA("Password should be at least 6 characters long")
        }
        else{
            axios.post(testUrl.serverUrl+"api/admin/create",{
                "username":uname,
                "password":event.target.pwd.value,
                "network":event.target.ntwk.value,
                "role":event.target.profile.value,
                "email":event.target.email.value
            }
            ,{ headers:{ "x-access-token":props.auth.token}}
            ).then(res=>{
                // console.log(res)
                setA(<div>
                    Please check your email and enter the confirmation code:<input id="cc" type="number"></input>
                    <Button onClick={()=>{
                        axios.post(testUrl.serverUrl+"api/admin/confirm",{
                            "username":uname,
                            "confirmationCode":document.getElementById("cc").value
                    },{ headers:{ "x-access-token":props.auth.token}}).then(res=>{
                        // console.log(res)
                        setB("User successfully created!")
                    })
                    }}>Submit</Button>
                </div>)
                })
        }
    }
    return (
        <>
            <Button className="newUserButton" onClick={handleShow}>Create a New User</Button>
            <Modal style={{"opacity":"1", "background-color": "rgba(19,19,19,0.8)", "padding-top":"30vh"}} show={show} onHide={handleClose}>
                <Modal.Title>
                    Create new user
                </Modal.Title>
                <Modal.Body id="userModal">
                    <form onSubmit={handleSubmit}>
                        <input id="uname" type="text" placeholder="User name" required></input>
                        <input id="pwd" type="password" placeholder="Password" required></input>
                        <input id="cpwd" type="password" placeholder="Confirm password" required></input>
                        <input id="email" type="email" placeholder="Email" required></input>
                        <label for="profile">Select Role:</label><select id="profile" required>
                            {/* <option>Select Profile</option> */}
                            <option>Operations Admin</option>
                            <option>Software Admin</option>
                            <option>Client</option>
                        </select>
                        <label for="ntwk">Select Network:</label>
                        <select id="ntwk" required>
                            {/* <option>Select Network</option> */}
                            <option>CWC</option>
                            <option>Kristnam</option>
                        </select>
                        <button>Submit</button>
                    </form>
                    <div>{a}</div>
                    <div>{b}</div>
                </Modal.Body>
            </Modal>
        </>
        )
}
export default CreateIssueModal