import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import {Redirect} from 'react-router-dom'
import './Logout.css'

class Logout extends Component {
  constructor(props) {
    super(props)
    // console.log(props)
  }
  handleLogOut = () =>{
    // console.log("Button clicked")
    // event.preventDefault();
    try {
        Auth.signOut();
        this.props.auth.setAuthStatus(false)
        this.props.auth.setUser(null)
    } catch (error) {
        console.log(error.message);
        // console.log(this.props)
    }
}
    
      render() {
      //   if(!this.props.auth.isAuthenticated){
      //     return(
      //       <Redirect
      //       to={{
      //         // this.props.history.push("/welcome")
      //     pathname: "/"
      //   }}
      // />)
      //   }
      //   else{

      return (
        <div className={!this.props.fromPage?"logoutButton":"bigButton"} onClick={ this.handleLogOut }>
          Log out
        </div>
      )
        // }
      }
}
export default Logout

