import {Modal} from "react-bootstrap"
import {Button} from "react-bootstrap"
import React, { useState, useEffect } from 'react';
import "./EditCalibrationEqnModal.css"
import axios from "axios"
import testUrl from "../../../config"

function EditCalibrationEqnModal(props) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [a, setA]=useState("")

    const handleSubmit = (event) => {
        event.preventDefault()
        let fn=event.target.fn.value
        if(fn.search("x")!==-1){
            let body={"device_id":props.deviceID,"calibEq":fn}
            console.log(body)
            axios.put(testUrl.serverUrl+"api/client/cali",body,{ headers:{ "x-access-token":props.auth.token}}).then(res=>{
                console.log(res)
                setA("Equation successfully changed")
            })
        }
        else{
            setA("There is no x in your function")
        }
    }
    return (
        <>
            <Button className="calibrationEqnButton" onClick={handleShow}>Change calibration equation for {props.deviceID}</Button>
            <Modal style={{"opacity":"1", "background-color": "rgba(19,19,19,0.8)", "padding-top":"30vh"}} show={show} onHide={handleClose}>
                <Modal.Title>
                    Assign attributes to {props.deviceID}
                </Modal.Title>
                <Modal.Body id="calibrationEqnModal">
                    <form onSubmit={handleSubmit}>
                        Type in the function(Use x as the variable): <input id="fn" type="text"/><br/>
                        <button>Submit</button>
                    </form>
                    <br/>
                    <div>{a}</div>
                </Modal.Body>
            </Modal>
        </>
        )
}
export default EditCalibrationEqnModal